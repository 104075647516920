import { dropzoneAttachmentFormats, dropzoneVideoFormats } from "assets/constants";
import { getGeneralName, renderErrorMessage } from "assets/functions";
import { TrashIcon } from "assets/svg";
// import { TrashIcon } from "/assets/svg";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({ name, multiple = true, fileFormats = "attachments" }) => {
	const { setFieldValue, values } = useFormikContext();

	const onDrop = useCallback(
		(acceptedFiles, rejectedFiles) => {
			setFieldValue(name, [
				...values[name],
				...acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				),
			]);
			if (rejectedFiles.length > 0) {
				renderErrorMessage("You uploaded some unsupported files, those files wont be added", 10000);
			}
		},
		[name, setFieldValue, values]
	);

	const { getRootProps, getInputProps, open } = useDropzone({
		multiple,
		noClick: true,
		accept: fileFormats === "attachments" ? dropzoneAttachmentFormats : dropzoneVideoFormats,
		onDrop,
	});

	return (
		<>
			<div className="w-full upload-file" {...getRootProps()}>
				<input {...getInputProps()} aria-label="input-file" />
				{values[name]?.length ? (
					<>
						{values[name].map((file, index) => (
							<RenderFile file={file} name={name} key={index} />
						))}
						{multiple && (
							<button type="button" className="upload-btn" onClick={open}>
								Add more files
							</button>
						)}
					</>
				) : (
					<>
						<button type="button" className="upload-btn" onClick={open}>
							Upload File
						</button>
						<p>Drag and drop some files here, or click to select files.</p>
						{!multiple && <p className="text-[1.2rem] text-red-500">Only one file is allowed, if you have multiple please zip it</p>}
					</>
				)}
			</div>
		</>
	);
};

const RenderFile = ({ file, name }) => {
	const { setFieldValue, values } = useFormikContext();
	//? check if file is new file
	//? if file is new upload get info from file object
	//? else get info from db response

	const extension = file.hasOwnProperty("id") ? file?.format : file.path.split(".").pop();
	const filename = file.hasOwnProperty("id") ? file?.public_id : file.path.split(".").slice(0, -1).join(".");

	return (
		<div className="flex w-full gap-2 pb-4 mb-4 border-b border-darkGrey">
			<p className="text-[1.4rem] font-semibold text-[#45464E] grow break-words capitalize">
				{filename}
				<span className="text-[#8B8D97] font-normal normal-case">.{extension}</span>
			</p>
			<p className="text-[1.4rem] text-[#45464E] break-words w-[150px]">{getGeneralName(`.${extension}`)}</p>
			<TrashIcon
				className="cursor-pointer stroke-primary"
				onClick={() =>
					setFieldValue(
						name,
						values[name].filter((fileValue) => fileValue.path !== file.path)
					)
				}
			/>
		</div>
	);
};

export default Dropzone;

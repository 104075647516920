import React from 'react'

import './Home.css'

const Feedback = () => {
    return (
        <section className='Feedback container mx-auto'>
            <div className="pt-20">
                <h1 className='w-full md:w-[30%] text-6xl font-semibold leading-tight text-darkBlue mb-7'>Feedback From Schools and Businesses</h1>
            </div>

            <div className="feedback-info mt-32 container flex flex-col md:flex-row justify-between items-center mb-96 gap-20">
                <div className="feedback bg-[#F8FBFF] p-16 rounded-2xl">
                    <h1 className='text-2xl font-[600] text-darkBlue mb-7'>Exceptional Content</h1>
                    <p className='text-grey mb-20 leading-loose'>The platform's interactive features, such as quizzes and assignments, help reinforce the learning material effectively. I can confidently say that my learning journey has been enriched.</p>
                    <p className='text-[#001E4A] text-2xl font-semibold'>Mark H.</p>
                </div>
                <div className="feedback bg-[#F8FBFF] p-16 rounded-2xl">
                    <h1 className='text-2xl font-[600] text-darkBlue mb-7'>My go-to Resource!</h1>
                    <p className='text-grey mb-20 leading-loose'>The course selection is up-to-date and covers cutting-edge technologies and industry trends. I wholeheartedly recommend this platform to anyone looking to invest in their professional growth.</p>
                    <p className='text-[#001E4A] text-2xl font-semibold'>Sarah L.</p>
                </div>
                <div className="feedback bg-[#F8FBFF] p-16 rounded-2xl">
                    <h1 className='text-2xl font-[600] text-darkBlue mb-7'>I’m enjoying Every Moment!</h1>
                    <p className='text-grey mb-20 leading-loose'>The video lessons are well-produced, and the instructors are not only talented artists but also excellent teachers. I appreciate the supportive community on the platform, where I can connect with other art enthusiasts, share my work, and receive constructive feedback.</p>
                    <p className='text-[#001E4A] text-2xl font-semibold'>Mrs Jennifer</p>
                </div>
            </div>
        </section>
    )
}

export default Feedback
import { useQuery } from "@tanstack/react-query";
import { getEnterpriseDetails } from "api";
import { useAppContext } from "context/AppContext";
import React from "react";
import { Helmet } from "react-helmet";
import { Outlet, useParams } from "react-router-dom";

const Code = () => {
	const { organization } = useParams();
	const { setHomepage } = useAppContext();

	const { data } = useQuery({
		queryKey: ["enterprise-details"],
		queryFn: () => getEnterpriseDetails(organization),
		onSuccess: (data) => {
			setHomepage(data?.data);
		},
		refetchOnWindowFocus: false,
	});

	return (
		<>
			<Helmet>
				<link rel="icon" href={data?.data?.logo ? `https://${data?.data?.logo}` : "%PUBLIC_URL%/favicon.ico"} />
			</Helmet>
			<Outlet />
		</>
	);
};

export default Code;

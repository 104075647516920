import MarketPlaceResults from "./MarketPlaceResults";
import MarketPlaceSideNavAccordion from "./MarketPlaceSideNavAccordion";
import { useParams, useSearchParams } from "react-router-dom";
import { filterCourses, getCategories } from "api";
import { useQueries } from "@tanstack/react-query";
import { Helmet } from "react-helmet";

const MarketPlace = () => {
	const [searchParams] = useSearchParams();
	const { organization } = useParams();
	const limit = 10;

	const search = searchParams.get("search");
	const rating = searchParams.get("rating");
	const page = searchParams.get("page") || 1;
	const categoryId = searchParams.get("categoryId");
	const courseLevel = searchParams.get("courseLevel");

	const [data, categories] = useQueries({
		queries: [
			{
				queryKey: ["search", search, rating, categoryId, courseLevel, organization, page],
				queryFn: () => filterCourses(search, rating, categoryId, courseLevel, page, limit, organization),
			},
			{
				queryKey: ["categories", organization],
				queryFn: () => getCategories({ all: 1, organization }),
			},
		],
	});

	const coursesData = data?.data;

	let searchResult;
	if (searchParams.has("search") && data?.result >= 1) {
		searchResult = ` ${data?.result} results for “${search}”`;
	} else if (searchParams.has("search") && data?.error?.message === "No Course Found") {
		searchResult = ` 0 results for “${search}”`;
	} else {
		searchResult = "";
	}

	return (
		<div className="w-full max-w-[1440px] mx-auto px-[3rem]">
			<Helmet>
				<meta charSet="utf-8" />
				<title>ClassCube Enterprise | Courses </title>
			</Helmet>
			<h6 className="text-black font-bold text-[24px] mt-10 mb-20">{searchResult}</h6>
			<div className="my-10 search-page">
				<MarketPlaceSideNavAccordion ratings={data} categories={categories?.data?.data?.categories} />
				<MarketPlaceResults data={coursesData} limit={limit} isLoading={data?.isLoading} />
			</div>
		</div>
	);
};
export default MarketPlace;

import React, { useEffect, useState } from 'react'
import { RiCloseLine, RiMenu4Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowDown } from "react-icons/md"
import logo from '../../assets/logo.png'
import schoolcube from "../../assets/svg/schoolcube.svg"
import schoolteller from "../../assets/svg/schoolteller.svg"
import examcentre from "../../assets/svg/examcentre.svg"
import { Link } from 'react-router-dom'
import { CustomPopup } from 'components'
import { ClasscubeIcon } from 'assets/svg'
import '../general.module.css'


const TopNav = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 850);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        const handleResize = () => {
            setIsMobileView(window.innerWidth < 850);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <div className={`${isSticky ? 'sticky top-0 z-[100] bg-white shadow-lg' : ''}`}>
                <div className='container mx-auto p-10 '>
                    <div className={`top-nav-bar ${isSticky ? 'sticky top-10' : ''}`}>
                        <img src={logo} alt="classcube logo" className="w-[15rem] object-contain" />

                        {isMobileView ? (
                            <>
                                <div className="cursor-pointer text-secondary" onClick={toggleMobileMenu}>{isMobileMenuOpen ? <RiCloseLine size={20} /> : <RiMenu4Line size={20} />}</div>
                            </>
                        ) : (
                            <>

                                <div className='web-nav'>

                                    <ul className="nav-link cursor-pointer">
                                        <li className='font-medium text-secondary text-[1.6rem] leading-5'><Link to="/">Home</Link></li>
                                        <CustomPopup
                                            trigger={
                                                <button
                                                    type="button"
                                                    className="font-medium text-secondary text-[1.6rem] leading-5 flex gap-3 items-center"
                                                >Packages
                                                    <MdOutlineKeyboardArrowDown />
                                                </button>
                                            }
                                            arrow={false}
                                            closeOnDocumentClick
                                            position={["bottom"]}
                                            width={"fit-content"}
                                            on={['hover', 'focus']}
                                            childrenArray={[
                                                {
                                                    text: <div className="flex items-start text-[1.6rem] gap-5 text-secondary font-medium cursor-pointer hover:text-[#0C78F1]">
                                                        <ClasscubeIcon className='my-2' />
                                                        <div>
                                                            Classcube Basic
                                                            <p className='text-[#5E6C85] my-2 text-[1.4rem] font-normal'>Smart, Effective, and Digital Learning</p>
                                                        </div>
                                                    </div>,
                                                    onClick: () => {
                                                        window.open("https://basic.classcube.online", "_blank")
                                                    },
                                                },
                                                {
                                                    text: <div className="flex items-start text-[1.6rem] gap-5 text-secondary font-medium cursor-pointer hover:text-[#0C78F1]">
                                                        <ClasscubeIcon className='my-2' />
                                                        <div>Classcube Pro
                                                            <p className='text-[#5E6C85] my-2 text-[1.4rem] font-normal'>All the courses you need in one place</p>
                                                        </div>
                                                    </div>,
                                                    onClick: () => {
                                                        window.open("https://pro.classcube.online", "_blank")
                                                    },
                                                },
                                                // {
                                                //     text: <div className="flex items-start text-[1.6rem] gap-5 text-secondary font-medium cursor-pointer hover:text-[#0C78F1]">
                                                //         <ClasscubeIcon className='my-2' />
                                                //         <div>Classcube Enterprise
                                                //             <p className='text-[#5E6C85] my-2 text-[1.4rem] font-normal'>Own your space, unleash your potential</p>
                                                //         </div>
                                                //     </div>,
                                                //     onClick: () => {
                                                //         window.open("https://enterprise.classcube.online", "_blank")
                                                //     },
                                                // },

                                            ]}
                                        />

                                        <li className='font-medium text-secondary text-[1.6rem] leading-5'><Link to="https://classcube.online/contact" target='_blank'>Contact Us</Link></li>

                                        <CustomPopup
                                            trigger={
                                                <button
                                                    type="button"
                                                    className="font-medium text-secondary text-[1.6rem] leading-5 flex gap-3 items-center">
                                                    Schoolrevs Suite
                                                    <MdOutlineKeyboardArrowDown />
                                                </button>
                                            }
                                            arrow={false}
                                            closeOnDocumentClick
                                            position={["bottom"]}
                                            width={"fit-content"}
                                            on={['focus', 'hover']}
                                            childrenArray={[
                                                {
                                                    text: <div className="flex items-center text-[1.6rem] gap-5 text-secondary font-medium cursor-pointer hover:text-[#0083ff]">  <img src={schoolcube} alt="schoolcube logo" width={35} />   Schoolcube.net</div>,
                                                    onClick: () => {
                                                        window.open("https://schoolcube.net", "_blank")
                                                    },
                                                },
                                                {
                                                    text: <div className="flex items-center text-[1.6rem] gap-5 text-secondary font-medium cursor-pointer hover:text-[#c92032]">  <img src={examcentre} alt="schoolcube logo" width={35} />  Examcentre.ng</div>,
                                                    onClick: () => {
                                                        window.open("https://examcentre.ng", "_blank")
                                                    },
                                                },
                                                {
                                                    text: <div className="flex items-center text-[1.6rem] gap-5 text-secondary font-medium cursor-pointer hover:text-[#743790]"> <img src={schoolteller} alt="schoolcube logo" width={35} />   Schoolteller.ng</div>,
                                                    onClick: () => {
                                                        window.open("https://schoolteller.ng", "_blank")
                                                    },
                                                },
                                            ]}
                                        />
                                    </ul>
                                </div>
                                <Link to="/register">
                                    <button className='bg-[#0C78F1] px-8 py-3 text-white rounded-md shadow-blue hover:bg-[#0062cc]'>
                                        Get started
                                    </button>
                                </Link>
                            </>
                        )}
                    </div>

                    {isMobileMenuOpen && (
                        <>
                            <ul className="cursor-pointer mobile">
                                <li className='font-medium text-secondary text-[1.6rem] leading-5'><Link to="/">Home</Link></li>
                                <CustomPopup
                                    trigger={
                                        <button
                                            type="button"
                                            className="font-medium text-secondary text-[1.6rem] leading-5 flex gap-3 items-center"
                                        >Packages
                                            <MdOutlineKeyboardArrowDown />
                                        </button>
                                    }
                                    arrow={false}
                                    closeOnDocumentClick
                                    position={["bottom"]}
                                    width={"fit-content"}
                                    on={['focus', 'hover']}
                                    childrenArray={[
                                        {
                                            text: <div className="productItems flex items-start text-[1.6rem] gap-5 text-secondary font-medium cursor-pointer hover:text-primary">
                                                <ClasscubeIcon className='my-2' />
                                                <div className='product-items '>Classcube Basic
                                                    <p className='text-[#5E6C85] my-2 text-[1.4rem] font-normal'>Smart, Effective, and Digital Learning</p></div>
                                            </div>,
                                            onClick: () => {
                                                window.open("https://basic.classcube.online", "_blank")
                                            },
                                        },
                                        {
                                            text: <div className="flex items-start text-[1.6rem] gap-5 text-secondary font-medium cursor-pointer hover:text-primary">
                                                <ClasscubeIcon className='my-2' />
                                                <div>Classcube Pro
                                                    <p className='text-[#5E6C85] my-2 text-[1.4rem] font-normal'>All the courses you need in one place</p></div>
                                            </div>,
                                            onClick: () => {
                                                window.open("https://pro.classcube.online", "_blank")
                                            },
                                        },
                                        // {
                                        //     text: <div className="flex items-start text-[1.6rem] gap-5 text-secondary font-medium cursor-pointer hover:text-primary">
                                        //         <ClasscubeIcon className='my-2' />
                                        //         <div>Classcube Enterprise
                                        //             <p className='text-[#5E6C85] my-2 text-[1.4rem] font-normal'>Own your space, unleash your potential</p></div>
                                        //     </div>,
                                        //     onClick: () => {
                                        //         window.open("https://enterprise.classcube.online", "_blank")
                                        //     },
                                        // },

                                    ]}
                                />

                                <li className='font-medium text-secondary text-[1.6rem] leading-5'><Link to="https://classcube.online/contact" target='_blank'>Contact Us</Link></li>

                                <CustomPopup
                                    trigger={
                                        <button
                                            type="button"
                                            className="font-medium text-secondary text-[1.6rem] leading-5 flex gap-3 items-center">
                                            Schoolrevs Suite
                                            <MdOutlineKeyboardArrowDown />
                                        </button>
                                    }
                                    arrow={false}
                                    closeOnDocumentClick
                                    position={["bottom"]}
                                    width={"fit-content"}
                                    on={['focus', 'hover']}
                                    childrenArray={[
                                        {
                                            text: <div className="flex items-center text-[1.6rem] gap-5  font-medium cursor-pointer hover:text-[#0083ff]">
                                                <img src={schoolcube} alt="schoolcube logo" width={35} />
                                                Schoolcube.net
                                            </div>,
                                            onClick: () => {
                                                window.open("https://schoolcube.net", "_blank")
                                            },
                                        },
                                        {
                                            text: <div className="flex items-center text-[1.6rem] gap-5 font-medium cursor-pointer hover:text-[#c92032]">
                                                <img src={examcentre} alt="schoolcube logo" width={35} />
                                                Examcentre.ng
                                            </div>,
                                            onClick: () => {
                                                window.open("https://examcentre.ng", "_blank")
                                            },
                                        },
                                        {
                                            text: <div className="flex items-center text-[1.6rem] gap-5 font-medium cursor-pointer hover:text-[#743790]">
                                                <img src={schoolteller} alt="schoolcube logo" width={35} />
                                                Schoolteller.ng
                                            </div>,
                                            onClick: () => {
                                                window.open("https://schoolteller.ng", "_blank")
                                            },
                                        },
                                    ]}
                                />
                            </ul>
                        </>
                    )}

                </div>
            </div>
        </>
    )
}
export default TopNav
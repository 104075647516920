import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
// import Progress from './Progress';
import { Link, useParams } from "react-router-dom";

const PaidCourses = ({ course }) => {
	const { organization } = useParams();

	return (
		<div className="overflow-hidden rounded-lg">
			<Link className="w-full overflow-hidden" to={`/${organization}/courses/${course?.slug}`}>
				<img src={`https://${course?.courseImg}`} className="w-full h-[215px]" alt="course" />
			</Link>
			<div className="p-[12px] [&>*]:mb-3">
				<Link className="block" to={`/${organization}/courses/${course?.slug}`}>
					<LinesEllipsis
						text={course?.name}
						className="text-black text-[1.7rem] font-bold hover:text-accent"
						maxLine="2"
						ellipsis="..."
						basedOn="letters"
					/>
				</Link>
				<p className="text-grey text-[1.4rem]">{course?.creator?.fullName}</p>
			</div>
		</div>
	);
};

export default PaidCourses;

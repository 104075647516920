import { AppFooter } from "components";
import React from "react";
import { Outlet } from "react-router-dom";

const AppLayout = () => {
	return (
		<>
			<Outlet />
			<AppFooter />
		</>
	);
};

export default AppLayout;

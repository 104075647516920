import { sanitizeHtmlOption } from "assets/constants";
import {
	// AwardIcon,
	ChartIcon,
	// ClockIcon,
	// EnrolledIcon,
	RefreshIcon,
	// TickIcon,
} from "assets/svg";
import { format, parseISO } from "date-fns";
import React from "react";
import sanitizeHtml from "sanitize-html";

const CourseOverview = ({ course }) => {
	return (
		<div className="max-w-full md:max-w-[650px] lg:max-w-[800px]">
			<h3 className="text-black font-bold text-[2rem] mb-5">Description</h3>
			<div
				className="text-grey text-[1.6rem] font-normal mb-10 text-justify"
				dangerouslySetInnerHTML={{
					__html: sanitizeHtml(course?.description, sanitizeHtmlOption),
				}}
			/>
			<h3 className="text-black font-bold text-[2rem] mb-5">Overview</h3>
			<div className="mb-10">
				<p className="text-grey text-[1.6rem] font-normal mb-5 flex gap-5 capitalize">
					<span>
						<ChartIcon />
					</span>
					{course?.courseLevel}
				</p>
				{/* <p className="text-grey text-[1.6rem] font-normal mb-5 flex gap-5">
					<span>
						<EnrolledIcon />
					</span>
					3,000 Total Enrolled
				</p> */}
				{/* <p className="text-grey text-[1.6rem] font-normal mb-5 flex gap-5">
					<span>
						<ClockIcon />
					</span>
					12 hours Duration
				</p> */}
				<p className="text-grey text-[1.6rem] font-normal mb-5 flex gap-5">
					<span>
						<RefreshIcon />
					</span>
					Last Updated {course?.updatedAt ? format(parseISO(course?.updatedAt), "PPP") : ""}
				</p>
				{/* <p className="text-grey text-[1.6rem] font-normal mb-5 flex gap-5">
					<span>
						<AwardIcon />
					</span>
					Certificate of Completion
				</p> */}
			</div>
			<h3 className="text-black font-bold text-[2rem] mb-5">Course Audience</h3>
			<div
				className="text-grey text-[1.6rem] font-normal mb-10 text-justify"
				dangerouslySetInnerHTML={{
					__html: sanitizeHtml(course?.audience, sanitizeHtmlOption),
				}}
			/>
			<h3 className="text-black font-bold text-[2rem] mb-5">Course Requirements & Tools</h3>
			<div
				className="text-grey text-[1.6rem] font-normal mb-10 text-justify"
				dangerouslySetInnerHTML={{
					__html: sanitizeHtml(course?.requirements, sanitizeHtmlOption),
				}}
			/>
			{/* <h3 className="text-black font-bold text-[2rem] mb-5">
				The Instructor
			</h3> */}
		</div>
	);
};

export default CourseOverview;

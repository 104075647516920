/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { verifyEmail } from 'api';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from "assets/logo.png";

const OrganisationComfirmation = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id") || '';

    const { isLoading, data, error, refetch } = useQuery({
        queryKey: ['email', id],
        queryFn: () => verifyEmail(id),
        suspense: true,
    });

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (error || data?.status !== 'success') {
        return <FailedVerificationScreen message={data?.message} refetch={refetch} />;
    }

    return <SuccessScreen message={data?.message} organisationName={data?.data?.user?.organisation_Name} />;
};

const LoadingScreen = () => (
    <div className="flex items-center justify-center flex-col">
        <div className="flex items-center justify-center mb-40">
            <img src={logo} className="w-[400px]" alt="ClassCube Enterprise" />
        </div>
        <span className="loader"></span>
    </div>
);

const FailedVerificationScreen = ({ message, refetch }) => (
    <div className="flex items-center justify-center flex-col">
        <div className="flex items-center justify-center mb-32">
            <img src={logo} className="w-[400px]" alt="ClassCube Enterprise" />
        </div>
        <h1 className='text-5xl mb-3'>{message}</h1>
        <p className='text-3xl'>Please restart the verification process</p>
        <button
            type="submit"
            className="btn w-[20%] bg-[#0372CE] text-white p-[15px] text-[1.4rem] mt-[40px]"
            onClick={() => {
                refetch()
            }}
        >
            Restart
        </button>
    </div>
);

const SuccessScreen = ({ organisationName, message }) => (
    <div className="flex items-center justify-center flex-col">
        <div className="flex items-center justify-center mb-32">
            <img src={logo} className="w-[400px]" alt="ClassCube Enterprise" />   
        </div>
        <h1 className='text-5xl mb-3'>{message}</h1>
        <p className='text-3xl '>Please login to proceed</p>
        <button
            type="submit"
            className="btn w-[20%] bg-[#0372CE] text-white p-[15px] text-[1.4rem] mt-[40px]"
            onClick={() => {
                window.location.replace(`https://enterprise.classcube.online/creator/${organisationName}`)
            }}
        >
            Login
        </button>
    </div>
);

export default OrganisationComfirmation;

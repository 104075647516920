import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";
import "./App.css";
import {
	Home,
	CourseDetails,
	Landing,
	MarketPlace,
	MyCourses,
	SetPassword,
	StudentSignUp,
	StudentsSignIn,
	Notifications,
	Settings,
	OrganizationSignUp,
} from "./pages";
import {
	AppLayout, //? adds bottom nav
	TopNavLayout,
	Redirect, //? used to check if page needs user to be logged in
	Code,
} from "layouts";
import Loader from "components/Loader";
import ErrorPage from "ErrorBoundary";
import { useAppContext } from "context/AppContext";
import Contact from "pages/Home/Contact";
import PrivacyPolicy from "pages/Home/PrivacyPolicy";
import { OrganisationComfirmation } from "pages/Authentication";

function App() {
	const { homepage } = useAppContext();
	return (
		<Suspense fallback={<Loader />}>
			<SkeletonTheme>
				<BrowserRouter>
					<Routes>
						<Route index element={<Home />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/privacy" element={<PrivacyPolicy />} />
						<Route path="/register" element={<OrganizationSignUp />} />
						<Route path="/register/confirmation" element={<OrganisationComfirmation />} />
						<Route path="/:organization" element={<Code />}>
							<Route element={<AppLayout />} errorElement={<ErrorPage />}>
								<Route element={<TopNavLayout />}>
									<Route path="" element={<Landing />} />
									<Route element={<Redirect />}>
										<Route path="my-courses" element={<MyCourses />} />
										<Route path="settings" element={<Settings />} />
										<Route path="notifications" element={<Notifications />} />
									</Route>
									<Route path="courses">
										<Route index element={<MarketPlace />} />
									</Route>
								</Route>
								<Route path="courses">
									<Route path=":slug" element={<CourseDetails />} />
								</Route>
							</Route>
							<Route element={<Redirect noAuth />}>
								<Route path="login" element={<StudentsSignIn />} />
								<Route path="setpassword" element={<SetPassword />} />
								{homepage?.allowStudentReg && <Route path="register" element={<StudentSignUp />} />}
							</Route>
						</Route>
					</Routes>
				</BrowserRouter>
			</SkeletonTheme>
		</Suspense>
	);
}

export default App;

import { createContext, useContext, useEffect, useState } from "react";
import { getFromStorage, setToStorage } from "assets/functions";
import { useCookies } from "react-cookie";

const AppContext = createContext({
	user: "",
	setUser: () => {},
	homepage: {},
	setHomepage: () => {},
	isLoggedIn: false,
	setCookie: () => {},
});

const AppProvider = ({ children }) => {
	const [user, setUser] = useState(getFromStorage("user") ?? null);
	const [homepage, setHomepage] = useState(getFromStorage("homepage") ?? null);
	const [cookies, setCookie] = useCookies(["jwt"]);

	useEffect(() => {
		setToStorage("user", user);
	}, [user]);

	useEffect(() => {
		setToStorage("homepage", homepage);
		var r = document.querySelector(":root");
		r.style.setProperty("--primary-color", homepage?.headerBgColor || "#0c78f1");
		r.style.setProperty("--accent-color", homepage?.footerBgColor || "#0080ff");
	}, [homepage]);

	return (
		<AppContext.Provider
			value={{
				user,
				setUser,
				isLoggedIn: !!cookies.jwt,
				setCookie,
				homepage,
				setHomepage,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppProvider;

export const useAppContext = () => useContext(AppContext);

import React, { useState } from "react";
import logo from "assets/logo.png";
import { ErrorMessage, Formik } from "formik";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { signIn } from "api";
import { useAppContext } from "context/AppContext";
import ForgotPassword from "./ForgetPassword";
import Modal from "components/Modal";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { renderErrorMessage } from "assets/functions";
import { Helmet } from "react-helmet";

const StudentsSignIn = () => {
	const { setUser, setCookie, setHomepage, homepage } = useAppContext();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { organization } = useParams();

	const [isCreateChapterModalOpen, setIsCreateChapterModalOpen] = useState(false);
	const [visible, setVisible] = useState(false);

	const signInSchema = yup.object().shape({
		email: yup.string().email("Please provide a valid email").required("Please provide a valid email"),
		password: yup
			.string()
			.required("Please provide password")
			.min(8, "Password must be 8 characters long")
			.matches(/[0-9]/, "Password requires a number")
			.matches(/[a-z]/, "Password requires a lowercase letter")
			.matches(/[A-Z]/, "Password requires an uppercase letter")
			.matches(/[^\w]/, "Password requires a symbol"),
	});

	const initialValues = {
		email: "",
		password: "",
	};

	const { mutate, isLoading } = useMutation(signIn, {
		onSuccess: (data) => {
			setUser(data?.data?.user);
			setHomepage(data?.data?.homepage);
			setCookie("jwt", data?.token);
			if (searchParams.has("redirect")) {
				navigate(`/${organization}/courses/${searchParams.get("redirect")}`);
			}
		},
		onError: (error) => renderErrorMessage(error.message),
	});

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>ClassCube Enterprise | Login </title>
			</Helmet>
			<div>
				<Link to={`/`} className="flex items-center justify-center mt-[100px] mb-[30px]">
					<img
						src={homepage?.logo ? `https://${homepage?.logo}` : logo}
						className="w-[160px] max-h-[120px] object-contain"
						alt="ClassCube Enterprise"
					/>
				</Link>
				<h1 className="text-center !text-primary text-[4.6rem] font-[700] mb-[15px] font-">Welcome Back!</h1>
				<p className="text-center text-grey text-[2rem] font-[400]">Please enter your details.</p>

				<div className=" d-flex justify-center align-center my-[50px]">
					<Formik
						initialValues={initialValues}
						validationSchema={signInSchema}
						onSubmit={(values) => mutate({ data: values, organization })}
					>
						{({ values, handleChange, handleBlur, handleSubmit }) => (
							<form className="w-[90%] max-w-[500px] mb-[100px]" onSubmit={handleSubmit}>
								<div className="form-group mb-[20px]">
									<label className="font-[500px] text-[1.4rem] text-black">Email Address</label>
									<input
										type="text"
										className="border control-form color-darkGrey"
										name="email"
										placeholder="Enter your Email Address"
										onChange={handleChange}
										value={values.email}
										onBlur={handleBlur}
									/>
									<ErrorMessage name="email" component="div" className="text-red-500  text-[1.4rem]" />
								</div>
								<div className="form-group mb-[20px]">
									<label className="font-[500px] text-[1.4rem] text-black">Password</label>
									<div className="has-pwd-icon">
										<input
											className="border control-form color-darkGrey"
											placeholder="Enter your password"
											type={visible ? "text" : "password"}
											name="password"
											onChange={handleChange}
											value={values.password}
											onBlur={handleBlur}
										/>
										{visible ? (
											<BsEye className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
										) : (
											<BsEyeSlash className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
										)}
									</div>
									<ErrorMessage name="password" component="div" className="text-red-500 text-[1.4rem]" />
								</div>
								<div className="justify-between form-group d-flex">
									<div className="form-check">
										<input className="form-check-input" type="checkbox" id="gridCheck" />
										<label className="ml-2 form-check-label text-[1.4rem]" htmlFor="gridCheck">
											Remember Me
										</label>
									</div>
									<button type="button" onClick={() => setIsCreateChapterModalOpen(true)}>
										Forgotten Password?
									</button>
								</div>
								<button
									type="submit"
									className="btn submit-signup w-full !bg-primary text-white p-[15px] text-[1.4rem]  mt-[4rem]"
									disabled={isLoading}
								>
									Login
								</button>
								{homepage?.allowStudentReg && (
									<p className="my-[20px] text-center">
										Don't have an account?
										<Link to={`/${organization}/register?${searchParams.toString()}`} className="!text-primary no-underline ml-2">
											Sign Up
										</Link>
									</p>
								)}
							</form>
						)}
					</Formik>
				</div>
			</div>

			<Modal
				isOpen={isCreateChapterModalOpen}
				setIsOpen={setIsCreateChapterModalOpen}
				title="Forgot Password?"
				subtitle="Enter your email to receive your verification code."
			>
				<ForgotPassword setter={setIsCreateChapterModalOpen} />
			</Modal>
		</>
	);
};
export default StudentsSignIn;

import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getMyCoursesPaginate } from "api";
import { CardSkeleton } from "skeleton";
import { PaidCourses } from "../../components";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import { ArrowLeftIcon, ArrowRightIcon2 } from "assets/svg";
import generalClasses from "../general.module.css";

const limit = 4;

const MyCourses = () => {
	const { organization } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page") || 1;

	const { isLoading, data } = useQuery({
		queryKey: ["my-courses", page, limit],
		queryFn: () => getMyCoursesPaginate(organization, page, limit),
		suspense: true,
	});

	const pageCount = Math.ceil(data?.data?.totalCount / limit);

	const handlePageClick = ({ selected }) => {
		searchParams.set("page", selected + 1);
		setSearchParams(searchParams);
	};

	const courseLength = data?.data?.courses.length;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>ClassCube Enterprise | My Courses </title>
			</Helmet>

			<div className="my-[2rem] px-[3rem] max-w-[1400px] mx-auto min-h-[80vh] flex flex-col justify-between">
				{courseLength <= 0 && (
					<>
						<div className="flex h-[80vh] flex-col items-center justify-center gap-5">
							∏<p className="text-primaryColor text-[2rem] md:max-w-[705px] font-bold"> You haven't purchased any course yet</p>
							<Link to={`/${organization}/courses`} className="LinesEllipsis hover:text-primaryColor text-grey text-[1.7rem] font-bold">
								{" "}
								Go to Marketplace
							</Link>
						</div>
					</>
				)}
				{courseLength > 0 && <h1 className="text-[2.8rem] text-black font-bold mb-[5rem] mt-[2rem]">My Courses</h1>}
				<div className="flex-1 grid gap-x-[15px] gap-y-[25px] min-[775px]:grid-cols-3 min-[550px]:grid-cols-2 min-[1090px]:grid-cols-4">
					{isLoading && <CardSkeleton count={8} />}
					{data?.data?.courses?.map((course, index) => (
						<PaidCourses key={index} course={course?.course} />
					))}
				</div>
				{courseLength > 0 && (
					<ReactPaginate
						nextLabel={
							<span className="flex items-center gap-1">
								Next <ArrowRightIcon2 />
							</span>
						}
						onPageChange={handlePageClick}
						pageRangeDisplayed={3}
						marginPagesDisplayed={2}
						pageCount={pageCount}
						previousLabel={
							<span className="flex items-center gap-1">
								<ArrowLeftIcon /> Previous{" "}
							</span>
						}
						pageClassName="page-item"
						pageLinkClassName={generalClasses.pageLink}
						previousClassName="page-item"
						previousLinkClassName={generalClasses.pageLink}
						nextClassName="page-item"
						nextLinkClassName={generalClasses.pageLink}
						breakLabel="..."
						breakClassName="page-item"
						breakLinkClassName={generalClasses.pageLink}
						containerClassName={generalClasses.pagination}
						activeClassName={generalClasses.active}
						renderOnZeroPageCount={null}
						forcePage={parseInt(searchParams.get("page") || 1) - 1}
					/>
				)}
			</div>
		</>
	);
};

export default MyCourses;

import { default as PopupComponent } from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Popup = ({ childrenArray, width = 200, title = "Action", ...props }) => {
	return (
		<PopupComponent {...props} contentStyle={{ width }}>
			{childrenArray?.map((child, index) => {
				if (child === null) return null;
				if (child?.hasOwnProperty("childrenArray")) {
					return <Popup key={index} {...child} />;
				} else {
					return (
						<div className="popup-option" key={index} onClick={child?.onClick}>
							{child?.text}
						</div>
					);
				}
			})}
		</PopupComponent>
	);
};

// export const PopupOption = styled.div`
// 	${(props) =>
// 		props.menu &&
// 		`
//         &:before, &:after {
//             border-right: 2px solid;
//             content: '';
//             display: block;
//             height: 8px;
//             margin-top: -7px;
//             position: absolute;
//             transform: rotate(135deg);
//             right: 10px;
//             top: 50%;
//             width: 0;
//         }

//         &:after {
//             margin-top: -3px;
//             transform: rotate(45deg);
//         }
//     `};
// `;

export default Popup;

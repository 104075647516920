import React, { useState } from "react";
import avatar from "assets/avatar.png";
import StarRatings from "react-star-ratings";
import LinesEllipsis from "react-lines-ellipsis";
import { getTimeAgo } from "assets/functions";

const Review = ({ data }) => {
	const [truncated, setTruncated] = useState(true);

	return (
		<div className="flex gap-4 mb-10">
			<img src={data?.profilePic ? data?.profilePic : avatar} className="w-[6rem] h-[6rem]" alt="avatar" />
			<div>
				<div className="flex items-center gap-5">
					<p className="text-black text-[1.6rem] capitalize font-semibold">
						{data?.student?.firstName} {data?.student?.lastName}
					</p>
					<p className="text-grey text-[1.4rem] font-normal">{getTimeAgo(data?.updatedAt)}</p>
				</div>
				<StarRatings rating={parseInt(data?.rating)} starRatedColor="#FBBF24" starDimension="20px" starSpacing="3px" />
				<div className="mt-4">
					{truncated ? (
						<LinesEllipsis
							text={data?.content}
							className="text-[#494c53] leading-[2.5rem] text-[1.6rem] font-normal"
							maxLine="4"
							ellipsis={
								<span
									className="cursor-pointer text-[1.4rem] text-accent"
									tabIndex={0}
									onClick={() => setTruncated((prevState) => !prevState)}
								>
									...Read More
								</span>
							}
							basedOn="words"
						/>
					) : (
						<p className="text-[#494c53] leading-[2.5rem]  text-[1.6rem] font-normal">
							{data?.content}
							<span
								className="cursor-pointer text-[1.4rem]  text-accent"
								tabIndex={0}
								onClick={() => setTruncated((prevState) => !prevState)}
							>
								...Read Less
							</span>
						</p>
					)}
				</div>
				{/* <div className="flex items-center gap-4 mt-8">
					<ThumbsUpIcon className="cursor-pointer stroke-black" tabIndex={0} />
					<ThumbsDownIcon
						className="cursor-pointer stroke-black"
						tabIndex={0}
					/>
					<Link
						to="#"
						className="text-black underline text-[1.4rem] font-semibold"
					>
						Report
					</Link>
				</div> */}
			</div>
		</div>
	);
};

export default Review;

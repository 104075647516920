import React from "react";
import Skeleton from "react-loading-skeleton";

const CardSkeleton = ({ count }) => {
	return (
		<>
			{Array(count)
				.fill(" ")
				.map((_, index) => (
					<div
						key={index}
						className="grid md:grid-cols-[repeat(auto-fit,_minmax(300px,1fr))] sm:grid-cols-[repeat(auto-fit,_minmax(250px,1fr))] grid-cols-[repeat(auto-fit,_minmax(200px,1fr))] gap-5"
					>
						<div className="overflow-hidden rounded-lg">
							<Skeleton height={200} className="w-full" />
						</div>
						<div className="p-[12px] [&>*]:mb-2">
							<Skeleton count={3} style={{ marginBottom: ".7rem" }} />
						</div>
					</div>
				))}
		</>
	);
};

export default CardSkeleton;

import React from "react";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "api";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const validationSchema = Yup.object().shape({
	email: Yup.string().email("Please provide a valid email").required("Please provide a valid email"),
});

const initialValues = {
	email: "",
};

const ForgotPassword = ({ setter }) => {
	const { organization } = useParams();

	const { mutate, isLoading } = useMutation(forgotPassword, {
		onSuccess: () => {
			Swal.fire({
				icon: "success",
				title: "Email Sent",
				text: "We have sent you an email that contains a link to reset your password",
				showConfirmButton: false,
				timer: false,
				confirmButtonText: "Back to Login",
				customClass: {
					container: "main-swal",
					htmlContainer: "custom-swal-htmlContainer",
					title: "custom-swal-title",
				},
			});
		},
		onError: (data) => {
			Swal.fire({
				icon: "error",
				title: data.message,
				text: "Please enter your correct email address",
				showConfirmButton: true,
				timer: false,
				confirmButtonText: "Try Again",
				customClass: {
					container: "main-swal",
					htmlContainer: "custom-swal-htmlContainer",
					title: "custom-swal-title",
					confirmButton: "custom-swal-confirm-button",
				},
			});
		},
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate({ data: values, organization })}>
			{({ values, handleChange, handleSubmit }) => {
				return (
					<>
						<form className="flex flex-col" onSubmit={handleSubmit}>
							<div className="form-group">
								<label className="lesson-label">Email Address</label>
								<input
									name="email"
									type="text"
									className="control-form"
									placeholder="hello@classcube.com"
									value={values.email}
									onChange={handleChange}
								/>
								<ErrorMessage name="email" component="div" className="text-red-500" />
							</div>
							<div className="ml-auto d-flex">
								<button type="submit" className="btn-form-primary" disabled={isLoading}>
									Submit
								</button>
							</div>
						</form>
					</>
				);
			}}
		</Formik>
	);
};
export default ForgotPassword;

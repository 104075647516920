import AppHeader from "components/AppHeader";
import React from "react";
import { Outlet } from "react-router-dom";

const TopNavLayout = () => {
	return (
		<div>
			<AppHeader />
			<Outlet />
		</div>
	);
};

export default TopNavLayout;

import React from "react";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
	AccordionItemState,
} from "react-accessible-accordion";
import { ArrowDownIcon, ArrowRightIcon1 } from "assets/svg";
import { useAppContext } from "context/AppContext";

const Faq = () => {
	const { homepage } = useAppContext();

	const faqs = homepage?.faqs?.reverse();

	return (
		<Accordion allowMultipleExpanded allowZeroExpanded className="max-w-[1200px] mx-[auto]">
			{faqs?.map((faq) => (
				<AccordionItem className="mb-[30px]" key={faq?.id}>
					<AccordionItemHeading>
						<AccordionItemButton className="questionbg">
							<div className="justify-between d-flex">
								<h2 className="question-heading">{faq?.header}</h2>
								<AccordionItemState>{({ expanded }) => (expanded ? <ArrowDownIcon /> : <ArrowRightIcon1 />)}</AccordionItemState>
							</div>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<p className="question-subheading">{faq?.content}</p>
					</AccordionItemPanel>
				</AccordionItem>
			))}
		</Accordion>
	);
};
export default Faq;

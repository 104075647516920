import { CheckIcon } from 'assets/svg'
import Persons from '../../assets/persons.png'
import React from 'react'

const GetStarted = () => {
    return (
        <section className='Get-started container flex flex-col md:flex-row justify-between py-52 mt-10'>
            <div className="g-content">
                <h1 className='w-full md:w-[60%] text-6xl font-semibold leading-tight text-darkBlue mb-10'>Getting Started with Classcube Enterprise</h1>
                <p className='w-full md:w-[58%] text-gray text-main mb-16'>You can get started with classcube enterprise with the following steps:</p>
                <div className='g-list flex flex-col md:flex-row gap-1'>
                    <ul>
                        <li className='flex gap-2'>
                            <CheckIcon />
                            <span className='text-grey text-2xl'>Sign up and create your account</span>
                        </li>
                        <li className='flex gap-2'>
                            <CheckIcon />
                            <span className='text-grey text-2xl'>Once your account is activated, log in with your credentials</span>
                        </li>
                        <li className='flex gap-2'>
                            <CheckIcon />
                            <p className='text-grey text-2xl'>Upon login, you'll be directed to your ClassCube Enterprise dashboard. Here, you can start creating your customized LMS solution</p>
                        </li>
                    </ul>
                    <ul>

                        <li className='flex gap-2'>
                            <CheckIcon />
                            <p className='text-grey text-2xl'>Tailor your LMS platform to your unique needs and branding.</p>
                        </li>
                        <li className='flex gap-2'>
                            <CheckIcon />
                            <p className='text-grey text-2xl'>Customize the layout, design, and features to match your organization's identity and specific requirements.</p>
                        </li>
                        <li className='flex gap-2'>
                            <CheckIcon />
                            <p className='text-grey text-2xl'>Leverage your customized LMS to streamline onboarding, staff training, and operation procedures in your business.</p>
                        </li>
                    </ul>
    
                </div>

            </div>
            <div className="g-img">
                <img src={Persons} alt="Persons" />
            </div>
        </section>
    )
}

export default GetStarted
import React, { useState } from "react";
import { Formik } from "formik";

import StarRatings from "react-star-ratings";
import { useMutation } from "@tanstack/react-query";
import { addReview } from "api";
import { renderSuccessMessage } from "assets/functions";
import { useParams } from "react-router-dom";

const RatingModal = ({ setIsOpen, refetch, courseId }) => {
	const { organization } = useParams();

	const [rating, setRating] = useState();

	const changeRating = (rating) => {
		setRating(rating);
	};

	const { mutate, isLoading } = useMutation(addReview, {
		onSuccess: (res) => {
			renderSuccessMessage(res?.status);
			setIsOpen(false);
			refetch();
		},
	});

	const initialValues = {
		content: "",
	};

	return (
		<>
			<div className="courseReview">How would you rate this course?</div>

			<div className="courseReviewRatings">
				<StarRatings
					rating={rating}
					starRatedColor="#FBBF24"
					starDimension="40px"
					starSpacing="3px"
					starHoverColor="#FBBF24"
					changeRating={changeRating}
					name="rating"
				/>
			</div>

			{rating && (
				<Formik
					initialValues={initialValues}
					onSubmit={({ content }) => {
						var data = {
							id: courseId,
							content: content,
							rating: rating,
						};
						mutate({ ...data, organization });
					}}
				>
					{({ values, handleChange, handleSubmit, setFieldValue }) => {
						return (
							<>
								<form onSubmit={handleSubmit}>
									<div className="form-group">
										<textarea
											name="content"
											type="text"
											className="courseReviewTextarea"
											placeholder="Tell us your experience from taking this course"
											value={values.content}
											onChange={handleChange}
										></textarea>
									</div>
									<div className="ml-auto d-flex">
										<button type="submit" className="btn-form-primary" disabled={isLoading}>
											Submit
										</button>
									</div>
								</form>
							</>
						);
					}}
				</Formik>
			)}
		</>
	);
};

export default RatingModal;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AppProvider from "context/AppContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const listOfCodeToNotRepeat = [500, 404];

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: (_, error) => !listOfCodeToNotRepeat.includes(error?.cause),
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<AppProvider>
				<App />
			</AppProvider>
			<ReactQueryDevtools />
		</QueryClientProvider>
	</React.StrictMode>
);

import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "./editor.css";

const CustomEditor = ({ errorState = null, setState, name }) => {
	return (
		<Editor
			wrapperStyle={{
				border: errorState ? "1px solid red" : "none",
			}}
			onEditorStateChange={(editorState) => setState(name, draftToHtml(convertToRaw(editorState.getCurrentContent())))}
			editorClassName="editor-class"
			toolbarClassName="toolbar-class"
		/>
	);
};

export const CustomEditorWithState = ({ errorState = null, setState, name, state }) => {
	return (
		<Editor
			wrapperStyle={{
				border: errorState ? "1px solid red" : "none",
			}}
			editorState={state}
			onEditorStateChange={(editorState) => setState(name, editorState)}
			editorClassName="editor-class"
			toolbarClassName="toolbar-class"
		/>
	);
};

export default CustomEditor;

import React from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
	const error = useRouteError();

	return (
		<div id="error-page" className="flex flex-col items-center justify-center h-screen">
			<h1 className="text-4xl">Oops!</h1>
			<p className="mt-2 text-lg">Sorry, an unexpected error has occurred.</p>
			<p className="mt-2 text-lg">
				<i>{error.statusText || error.message}</i>
			</p>
		</div>
	);
}

// export default class ErrorBoundary extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = { hasError: false, error: null };
// 	}

// 	static getDerivedStateFromError(error) {
// 		// Update state so the next render will show the fallback UI.
// 		return { hasError: true, error };
// 	}

// 	render() {
// 		if (this.state.hasError) {
// 			// You can render any custom fallback UI
// 			return (
// 				<div
// 					id="error-page"
// 					className="flex flex-col items-center justify-center h-screen"
// 				>
// 					<h1 className="text-4xl">Oops!</h1>
// 					<p className="mt-2 text-lg">
// 						Sorry, an unexpected error has occurred.
// 					</p>
// 					<p className="mt-2 text-lg">
// 						<i>{this.error.statusText || this.error.message}</i>
// 					</p>
// 				</div>
// 			);
// 		}

// 		return this.props.children;
// 	}
// }

import Header from "./Header";

import Main from "./Main";

const Home = () => {
	return (
		<>
			<Header />
			<Main />
		</>
	);
};

export default Home;

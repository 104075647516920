import React from 'react'

import frame from '../../assets/Frame 427321081.png'
import frame2 from '../../assets/Frame 427321082.png'


import './Home.css';

const Features = () => {
    return (
        <section className='Features bg-[#F9FCFF] py-36'>
            <div className='container mb-36 mx-auto flex flex-col lg:flex-row justify-between'>
                <div className="container text-center">
                    <h1 className='w-full md:w-[50%] text-6xl font-semibold mx-auto leading-tight text-darkBlue mb-7'>Take Control and Make it Your Own</h1>
                    <p className='w-full md:w-[58%] text-gray mx-auto text-main'>Whether you're an educational institution, a corporate training provider, or an individual creator, ClassCube Business empowers you to create an online learning platform that reflects your brand's identity and delivers a personalized learning experience to your students.</p>
                </div>
            </div>
            <div className="feature-info w-full lg:w-[65%] container mx-auto p-0 md:px-40">
                <div className="feature container flex flex-col-reverse md:flex-row justify-between gap-10 items-center mb-32">
                    <div className="control-image">
                        <img src={frame} alt="" />
                    </div>
                    <div className="control-text w-full md:w-[45%] mb-14 md:mb-0">
                        <h2 className='text-4xl text-darkBlue font-semibold mb-7'>Customized Certificates</h2>
                        <p className='text-gray text-main'>The platform issues branded certificates of completion, recognizing and celebrating the professional growth and accomplishments of your employees.</p>
                    </div>
                </div>
                <div className="feature container flex flex-col md:flex-row justify-between gap-10 items-center">
                    <div className="control-text w-full md:w-[45%] mb-14 md:mb-0">
                        <h2 className='text-4xl text-darkBlue font-semibold mb-7'>Analytics and Insights</h2>
                        <p className='text-gray text-main'>TMeasure Success - Access detailed analytics and insights, enabling you to assess the effectiveness of your training initiatives and identify areas for improvement.</p>
                    </div>
                    <div className="control-image">
                        <img src={frame2} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features
import React, { useState, useEffect } from "react";
import { CoursesGrid, PaidCourses, TopCourses } from "components";
import { useAppContext } from "context/AppContext";
import { Link, useParams } from "react-router-dom";
import { featuredCourses, getCategories, getMyCourses, topCourses } from "api";
import { useQuery } from "@tanstack/react-query";
import { CardSkeleton } from "skeleton";
import { Helmet } from "react-helmet";
import Faq from "./Faq";

const Landing = () => {
	const { organization } = useParams();
	const { isLoggedIn, user } = useAppContext();

	const { isLoading: topCoursesIsLoading, data: topCoursesData } = useQuery({
		queryKey: ["featured", organization],
		queryFn: () => topCourses(organization),
	});

	if (isLoggedIn && user) {
		return <AuthLanding loading={topCoursesIsLoading} data={topCoursesData} />;
	} else {
		return <NoAuthLanding loading={topCoursesIsLoading} data={topCoursesData} />;
	}
};

const NoAuthLanding = ({ loading, data }) => {
	const { organization } = useParams();
	const { homepage } = useAppContext();

	const [selectedCategories, setSelectedCategories] = useState(null);
	const [selectedCategoriesName, setSelectedCategoriesName] = useState(null);

	const { data: categoriesData } = useQuery({
		queryKey: ["categories", organization],
		queryFn: () => getCategories({ organization }),
		suspense: true,
	});

	useEffect(() => {
		setSelectedCategories(categoriesData?.data?.categories[0]?.id);
		setSelectedCategoriesName(categoriesData?.data?.categories[0]?.id);
	}, [categoriesData]);

	const { isLoading: featuredIsLoading, data: feturedCourses } = useQuery({
		queryKey: ["featured", selectedCategoriesName, organization],
		queryFn: () => featuredCourses(selectedCategoriesName, organization),
		enabled: !!selectedCategoriesName,
	});

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>ClassCube Enterprise | Home </title>
			</Helmet>
			<div className={`px-size pt-28 mb-20 landing-contain min-h-[450px] max-[1080px]:min-h-[350px] relative`}>
				<HeroSection />
				<div className="max-w-[1400px] mx-auto">
					<div className="flex flex-col gap-5 max-w-[600px] pb-[55px] z-20 relative">
						<h1 className="text-primaryColor text-[4.6rem] md:text-[5rem] md:max-w-[675px] font-bold">{homepage?.primaryHeader}</h1>
						<p className="text-grey text-[2rem] font-light md:max-w-[500px]">{homepage?.secondaryHeader}</p>
						<Link
							to={`/${organization}/courses`}
							className="bg-accent text-white w-[180px] text-[1.6rem] p-[12px] text-center rounded-md my-3"
						>
							Explore Our Courses
						</Link>
					</div>
				</div>
			</div>
			<div className="max-w-[1400px] mx-auto px-[3rem]">
				<div className="flex flex-nowrap gap-4 mb-20 border-b border-[#E7E5EA] max-w-[90vw] overflow-x-auto">
					{categoriesData?.data?.categories.map((category, index) => (
						<div
							key={index}
							className={`px-4 py-4 cursor-pointer min-w-fit capitalize hover:text-accent ${
								selectedCategories === category.id ? "text-accent border-b-2 border-b-accent" : "text-grey"
							}`}
							tabIndex={0}
							onClick={() => {
								setSelectedCategories(category.id);
								setSelectedCategoriesName(category.id);
							}}
						>
							{category.name}
						</div>
					))}
				</div>

				{feturedCourses?.data?.courses?.rows < 1 ? (
					<div className="flex items-center flex-col gap-7 mb-[5rem] mt-[15rem]">
						<p className="text-grey text-[2rem] font-light">No Brought Courses Yet?...</p>
						<Link to={`/${organization}/courses`} className="explore-link text-grey text-[1.6rem] p-[12px] px-5 rounded-md">
							Explore Now
						</Link>
					</div>
				) : (
					<>
						<CoursesGrid title="Featured Courses" isLoading={featuredIsLoading} data={feturedCourses} />
						<TopCourses title="Top Courses" isLoading={loading} data={data} />
					</>
				)}
			</div>
			<div className=" max-w-[1400px] mx-auto py-10 mb-10 px-[3rem]">
				<h2 className="faq-header">Frequently Asked Questions</h2>
				<Faq />
			</div>
		</>
	);
};

const AuthLanding = ({ loading, data }) => {
	const { organization } = useParams();
	const { user, homepage } = useAppContext();

	const { isLoading: myCourseIsLoading, data: myCourseData } = useQuery({
		queryKey: ["my-courses", organization],
		queryFn: () => getMyCourses(organization),
	});

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>ClassCube Enterprise | Home </title>
			</Helmet>
			<div className={`px-size pt-28 mb-20 landing-contain min-h-[450px] max-[1080px]:min-h-[350px] relative`}>
				<HeroSection />
				<div className="max-w-[1400px] mx-auto">
					<div className="flex flex-col gap-5 max-w-[600px] pb-[55px] z-20 relative">
						<h1 className="text-primaryColor text-[5.6rem] md:max-w-[705px] font-bold">{homepage?.primaryHeader}</h1>
						<p className="text-grey text-[2rem] font-light md:max-w-[500px]">{homepage?.secondaryHeader}</p>
						<div className="flex gap-5">
							<Link to={`/${organization}/courses`} className="bg-primary text-white text-[1.6rem] p-[12px] px-5 rounded-md">
								Marketplace
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="px-[3rem]">
				{myCourseIsLoading && (
					<>
						<div className="flex items-center justify-between  mb-[5rem] mt-[2rem]">
							<h1 className="text-[2.8rem] text-black capitalize font-bold">Continue Learning, {user?.firstName}</h1>
							<Link to={`/${organization}/my-courses`} className="text-accent hover:underline font-bold text-[2rem]">
								My Courses
							</Link>
						</div>
						<div className="grid gap-x-[15px] gap-y-[25px] min-[550px]:grid-cols-2 min-[1090px]:grid-cols-3">
							<CardSkeleton count={6} />
						</div>
					</>
				)}
				{myCourseData?.data?.courses.length < 1 && (
					<div>
						<div className="flex items-center flex-col gap-7 mb-[5rem] mt-[15rem]">
							<p className="text-grey text-[2rem] font-light">Welcome {user?.firstName}</p>
							<p className="text-grey text-[2rem] font-light">No Brought Courses Yet?...</p>
							<Link to={`/${organization}/courses`} className="explore-link text-grey text-[1.6rem] p-[12px] px-5 rounded-md">
								Explore Now
							</Link>
						</div>
					</div>
				)}
				<div className="max-w-[1400px] mx-auto min-h-[30vh]">
					{myCourseData?.data?.courses.length > 0 && (
						<div>
							<div className="flex items-center justify-between  mb-[5rem] mt-[2rem]">
								<h1 className="text-[2.8rem] text-black capitalize font-bold">Continue Learning, {user?.firstName}</h1>
								<Link to={`/${organization}/my-courses`} className="text-accent hover:underline font-bold text-[2rem]">
									My Course
								</Link>
							</div>
							<div className="grid md:grid-cols-[repeat(auto-fill,_minmax(300px,1fr))] sm:grid-cols-[repeat(auto-fit,_minmax(250px,1fr))] grid-cols-[repeat(auto-fit,_minmax(200px,1fr))] gap-10">
								{myCourseData?.data?.courses?.map((course, index) => (
									<PaidCourses key={index} course={course?.course} />
								))}
							</div>
						</div>
					)}
					<div className="my-[60px]">
						<TopCourses title="Top Courses" isLoading={loading} data={data} />
					</div>
				</div>
			</div>
		</>
	);
};

const HeroSection = () => {
	const { homepage } = useAppContext();

	return (
		!!homepage?.home_image && (
			<picture className="absolute top-0 left-0 z-0 w-full h-full">
				<source
					media="(max-width:500px)"
					srcset={homepage?.home_image_small ? `https://d1lgfn0v719yng.cloudfront.net/${homepage?.home_image_small}` : ""}
					className="w-full h-full"
				/>
				<source
					media="(max-width:1080px)"
					srcset={homepage?.home_image_mid ? `https://d1lgfn0v719yng.cloudfront.net/${homepage?.home_image_mid}` : ""}
					className="w-full h-full"
				/>
				<img
					src={homepage?.home_image ? `https://d1lgfn0v719yng.cloudfront.net/${homepage?.home_image}` : ""}
					alt="Flowers"
					className="w-full h-full"
				/>
			</picture>
		)
	);
};
export default Landing;

import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Credentials": true,
	},
	withCredentials: true,
});

api.interceptors.response.use(
	(response) => response.data,

	(error) => {
		if (error.response.status === 401) {
			renderErrorMessage(error.response.data.message).then((organization) => {
				Cookies.remove("jwt");
				window.location.href = "/";
			});
		} else if (error.response.status === 503) {
			renderSuccessMessage(error.response.data.message);
			throw new Error(error.response.data.message ?? "Account Created");
		} else {
			renderErrorMessage(error.response.data.message);
			throw new Error(error.response.data.message, {
				cause: error.response.status,
			});
		}
	}
);

//* Authentication
export const signIn = ({ data, organization }) => {
	return api.post(`/${organization}/auth/student/login`, data);
};

export const signUp = ({ data, organization }) => {
	return api.post(`/${organization}/auth/student/signup`, data);
};
export const forgotPassword = ({ data, organization }) => {
	return api.post(`/${organization}/auth/student/forgotpassword`, data);
};
export const resetPassword = (data) => {
	return api.post(`auth/student/resetpassword/`, data);
};
export const setPassword = ({ token, data, organization }) => {
	return api.post(`/${organization}/auth/student/resetpassword/${token}`, data);
};
export const updatePassword = ({ data, organization }) => {
	return api.patch(`/${organization}/auth/student/updatePassword`, data);
};


// * Courses
export const getCourse = (id, organization) => api.get(`/${organization}/app/courses/${id}`);

export const getCourseBySlug = (slug, organization) => api.get(`/${organization}/app/courses/slug/${slug}`);

export const getMyCourses = (organization) => api.get(`/${organization}/student/mycourses`);

export const getMyCoursesPaginate = (organization, page, limit) => {
	return api.get(`/${organization}/student/paginate-mycourses`, {
		params: { page, limit },
	});
};

export const getMyCourseById = (id, organization) => api.get(`/${organization}/student/mycourses/${id}`);

export const getMyCourseBySlug = (slug, organization) => api.get(`/${organization}/student/mycourses/${slug}`);

export const getCategories = ({ all = 0, organization }) => {
	return api.get(`/${organization}/app/categories`, {
		params: all === 1 ? { all: 1 } : null,
	});
};
export const filterCourses = (search, rating = 1, categoryId, courseLevel, page, limit, organization) => {
	return api.get(`/${organization}/app/courses`, {
		params: { rating, name: search, categoryId, courseLevel, page, limit },
	});
};

export const getUserNotifications = (organization, page, limit) => {
	return api.get(`/${organization}/student/notifications`, {
		params: { page, limit },
	});
};

export const featuredCourses = (categories, organization) => {
	return api.get(`/${organization}/app/courses`, {
		params: {
			category: categories,
			isFeatured: 1,
		},
	});
};

export const getstudentprofile = (organization) => {
	return api.get(`/${organization}/student/profile`);
};

export const updatestudentprofile = ({ data, organization }) => {
	return api.patch(`/${organization}/student/profile/`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

export const topCourses = (organization) => {
	return api.get(`/${organization}/app/rated/courses`, {
		params: {
			startRate: 3,
			endRate: 4.7,
			limit: 8,
		},
	});
};

//* Payment
export const buyCourse = (courseId, organization) => {
	return api.get(`/${organization}/payment/init-payment/${courseId}`);
};

export const verifyPayment = ({ transactionReference }, organization) => {
	return api.get(`/${organization}/payment/verify-payment/${transactionReference}`);
};

export const claimCourse = (courseId, organization) => {
	return api.get(`/${organization}/payment/claimCourse/${courseId}`);
};

//* Course Reviews
export const addReview = ({ id, content, rating, organization }) => {
	return api.post(`/${organization}/student/review/${id}`, {
		content: content,
		rating: rating,
	});
};

export const editReview = ({ id, content, rating, organization }) => {
	return api.patch(`/${organization}/student/review/${id}`, {
		content: content,
		rating: rating,
	});
};

export const deleteReview = (id, organization) => {
	return api.delete(`/${organization}/student/review/${id}`);
};

export const getAllReviews = (courseId, organization, page, limit) => {
	return api.get(`/${organization}/app/allReviews/${courseId}`, {
		params: {
			page,
			limit,
		},
	});
};

export const submitAssignment = ({ data, organization }) =>
	api.post(`/${organization}/assignment/student/`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});

export const markAsComplete = ({ lessonId, courseId, organization }) =>
	api.post(`/${organization}/student/addLessonId`, {}, { params: { lessonId, courseId } });

export const getCourseProgress = (id, organization) => api.get(`/${organization}/student/progress/${id}`);

export const getSubmissionState = (id, organization) => api.get(`/${organization}/assignment/student/${id}`);

export const getEnterpriseDetails = (organization) => api.get(`/${organization}/enterprise/${organization}`);

export const orgsignup = (data) => {
	return api.post("/super-admin/request-enterprise", data);
};

export const verifyEmail = (id) => {
	return api.get(`/super-admin/verify-email/${id}`);
};

import React from 'react'
import Benefits from "./Benefits";
import Footer from "./Footer";
import Features from './Features';
import Support from './Support';
import Feedback from './Feedback';
import GetStarted from './GetStarted';

const Main = () => {
    return (
        <main className=''>
            <Benefits />
            <Features />
            <GetStarted />
            <Support />
            <Feedback />
            <Footer />
        </main>
    )
}

export default Main
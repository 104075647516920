const Progress = ({ percentage, showTop, topComponent, reverseOrder, style = { width: "25vw" } }) => {
	return (
		<div className={`flex gap-2 ${reverseOrder ? "flex-col-reverse" : "flex-col"}`}>
			{!!showTop && !!topComponent ? (
				topComponent(percentage)
			) : (
				<div className="flex items-center justify-between mb-1">
					{/* <p className="text-white text-[1.3rem]">Course Progress</p> */}
					<p className="text-white text-[1.3rem] ml-auto">{Math.round(percentage)}% Completed</p>
				</div>
			)}
			<div className="h-3 overflow-hidden bg-white" style={style}>
				<div className="h-full bg-accent" style={{ width: `${percentage}%` }} />
			</div>
		</div>
	);
};
export default Progress;

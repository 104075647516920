import React from "react";
import Skeleton from "react-loading-skeleton";

const ReviewsSkeleton = ({ count }) => {
	return (
		<>
			{Array(count)
				.fill(" ")
				.map((_, index) => (
					<div key={index} className="flex items-start w-full gap-10 mb-10">
						<Skeleton circle width={70} height={70} />
						<div className="w-full">
							<div className="flex items-center gap-5">
								<Skeleton width={250} />
								<Skeleton width={100} />
							</div>
							<Skeleton width={250} />

							<div className="mt-4">
								<Skeleton count={4} className="w-full" />
							</div>
						</div>
					</div>
				))}
		</>
	);
};

export default ReviewsSkeleton;

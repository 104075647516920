import { fileTypesImages } from "assets/constants";
import { getGeneralName } from "assets/functions";
import React from "react";

const CourseDownloads = ({ selectedLesson }) => {
	return (
		<div className="flex flex-wrap gap-4">
			{selectedLesson?.attachment
				?.filter((attachment) => attachment !== "")
				?.map((attachment, index) => (
					<LessonAttachement attachment={attachment} key={index} />
				))}
		</div>
	);
};

export const LessonAttachement = ({ attachment }) => {
	const extension = attachment ? `.${attachment.split(".").slice(-1)[0]}` : "";
	const names = attachment ? attachment.split(".").slice(0, -1).join(".").split("~~~")[0].split("/")[1].toUpperCase() : "";

	return (
		<a
			href={`https://${attachment}`}
			download={true}
			className="text-[1.35rem] flex items-center px-[12px] py-[10px] border rounded-lg gap-3 w-fit"
		>
			{fileTypesImages[getGeneralName(extension)]} {names?.concat(extension)}
		</a>
	);
};

export default CourseDownloads;

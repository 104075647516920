import React from 'react'
import logo from "../../assets/logo.png"
import { Link } from 'react-router-dom'
// import { RiFacebookFill, RiInstagramFill, RiLinkedinFill } from "react-icons/ri"


const Footer = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='flex flex-col md:flex-row gap-10 md:justify-between md:items-center'>
                    <div className="text-darkBlue text-[3.2rem] font-semibold md:max-w-[60%] lg:max-w-[50%]">Join Other Schools & Businesses Using Classcube Today!</div>

                    <Link to="/register">
                        <button className='bg-[#0C78F1] px-8 py-3 text-white rounded-md shadow-blue hover:bg-[#0062cc]'>
                            Get started
                        </button>
                    </Link>                </div>
                <div className='flex flex-col md:flex-row gap-[20px] md:justify-between md:items-center my-[50px]'>
                    <div className='flex justify-between md:w-[50%]'>
                        <img src={logo} alt="classcube logo" className='w-[15rem] object-contain' />

                        {/* <div className='flex items-center justify-between gap-5'>
                            <RiInstagramFill className='text-secondary text-[2.5rem] cursor-pointer' />
                            <RiFacebookFill className='text-secondary text-[2.5rem] cursor-pointer ' />
                            <RiLinkedinFill className='text-secondary text-[2.5rem] cursor-pointer' />
                        </div> */}
                    </div>
                    <div className='text-gray text-[1.5rem] font-normal  leading-[2.2rem]'>© Copyright {new Date().getFullYear()} Revocube. All Rights Reserved
                        <Link to={'https://classcube.online/privacy'} target='_blank' className='text-blue ml-2'>Privacy and Policy</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
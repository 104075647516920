import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { updatePassword } from "api";
import { renderSuccessMessage } from "assets/functions";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useParams } from "react-router-dom";

const PasswordUpdate = () => {
	const { organization } = useParams();

	const [visible, setVisible] = useState(false);
	const [visible1, setVisible1] = useState(false);
	const [visible2, setVisible2] = useState(false);

	const registerSchema = yup.object().shape({
		oldPassword: yup
			.string()
			.required("Please provide your old password")
			.min(8, "Password must be 8 characters long")
			.matches(/[0-9]/, "Password requires a number")
			.matches(/[a-z]/, "Password requires a lowercase letter")
			.matches(/[A-Z]/, "Password requires an uppercase letter")
			.matches(/[^\w]/, "Password requires a symbol"),
		newPassword: yup
			.string()
			.required("Please provide new password")
			.min(8, "Password must be 8 characters long")
			.matches(/[0-9]/, "Password requires a number")
			.matches(/[a-z]/, "Password requires a lowercase letter")
			.matches(/[A-Z]/, "Password requires an uppercase letter")
			.matches(/[^\w]/, "Password requires a symbol"),
		confirmPassword: yup
			.string()
			.required("Confirm your new password")
			.oneOf([yup.ref("newPassword"), null], "Password don't match"),
	});

	const initialValues = {
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	};

	const { mutateAsync, isLoading } = useMutation(updatePassword, {
		onSuccess: (res) => {
			renderSuccessMessage(res?.message);
		},
	});

	return (
		<div>
			<div className=" max-w-[900px] flex flex-col">
				<Formik
					initialValues={initialValues}
					validationSchema={registerSchema}
					onSubmit={({ confirmPassword, ...values }, { resetForm }) => mutateAsync({ data: values, organization }).then(() => resetForm())}
				>
					{({ values, handleChange, handleBlur, handleSubmit }) => (
						<>
							<form className="max-w-[900px] flex flex-col" onSubmit={handleSubmit}>
								<div className="form-group mb-[20px]">
									<label className="font-normal text-[1.4rem] mb-3 text-[#47505B]">Old Password</label>
									<div className="has-pwd-icon">
										<input
											className="border control-form color-darkGrey"
											placeholder="Enter your old password"
											type={visible ? "text" : "password"}
											name="oldPassword"
											onChange={handleChange}
											value={values.oldPassword}
											onBlur={handleBlur}
										/>
										{visible ? (
											<BsEye className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
										) : (
											<BsEyeSlash className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
										)}
									</div>
									<ErrorMessage name="oldPassword" component="div" className="text-red-500 text-[1.4rem]" />
								</div>

								<div className="form-group mb-[20px]">
									<label className="font-normal text-[1.4rem] mb-3 text-[#47505B]">New Password</label>
									<div className="has-pwd-icon">
										<input
											className="border control-form color-darkGrey"
											placeholder="Enter your new password"
											type={visible1 ? "text" : "password"}
											name="newPassword"
											onChange={handleChange}
											value={values.newPassword}
											onBlur={handleBlur}
										/>
										{visible1 ? (
											<BsEye className="view-pwd" size={20} onClick={() => setVisible1((prevState) => !prevState)} />
										) : (
											<BsEyeSlash className="view-pwd" size={20} onClick={() => setVisible1((prevState) => !prevState)} />
										)}
									</div>
									<ErrorMessage name="newPassword" component="div" className="text-red-500 text-[1.4rem]" />
								</div>

								<div className="form-group mb-[20px]">
									<label className="font-normal text-[1.4rem] mb-3 text-[#47505B]">Confirm Password</label>
									<div className="has-pwd-icon">
										<input
											className="border control-form color-darkGrey"
											name="confirmPassword"
											placeholder="Re-type your password"
											type={visible2 ? "text" : "password"}
											onChange={handleChange}
											value={values.confirmPassword}
											onBlur={handleBlur}
										/>
										{visible2 ? (
											<BsEye className="view-pwd" size={20} onClick={() => setVisible2((prevState) => !prevState)} />
										) : (
											<BsEyeSlash className="view-pwd" size={20} onClick={() => setVisible2((prevState) => !prevState)} />
										)}
									</div>
									<ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-[1.4rem]" />
								</div>

								<button type="submit" disabled={isLoading} className="btn-form-primary">
									Update Password
								</button>
							</form>
						</>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default PasswordUpdate;

const CustomInput = ({ title, value, name, handleChange, setFieldValue, variant, leftIcon, options, children, containerStyle, ...others }) => {
	switch (variant) {
		case "text-area":
			return (
				<div className="form-wrapper">
					<label htmlFor={`text-area-${name}`} className="form-label">
						{title}
					</label>
					<textarea name={name} value={value} onChange={handleChange} className="custom-input" {...others} id={`text-area-${name}`} />
					{children}
				</div>
			);
		case "select":
			return (
				<div className="form-wrapper">
					<label htmlFor={`select-${name}`} className="form-label">
						{title}
					</label>
					<div className="my-custom-select">
						<select name={name} value={value} onChange={handleChange} {...others}>
							<option value="" label="Select" selected disabled />
							{options.map((option, index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
					</div>
					{children}
				</div>
			);
		case "icon-text":
		case "no-title":
			return (
				<div
					className="flex gap-4 px-5 py-4 bg-white rounded-lg text-[1.6rem] text-grey items-center  focus-within:border-accent"
					style={containerStyle}
				>
					{leftIcon && leftIcon}
					<input
						type="text"
						name={name}
						value={value}
						onChange={(event) => handleChange(event.target.value)}
						className="placeholder:text-[1.6rem] flex-1 placeholder:text-grey outline-0"
						{...others}
					/>
					{children}
				</div>
			);
		default:
			return (
				<div className="flex flex-col text-[1.6rem] text-grey">
					<label htmlFor={`text-input-${name}`} className="form-label">
						{title}
					</label>
					<input
						type="text"
						name={name}
						value={value}
						onChange={(event) => handleChange(event.target.value)}
						className="placeholder:text-[1.6rem] placeholder:text-grey"
						id={`text-input-${name}`}
						{...others}
					/>
					{children}
				</div>
			);
	}
};

export default CustomInput;

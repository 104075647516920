import React from "react";
import StarRatings from "react-star-ratings";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
	AccordionItemState,
} from "react-accessible-accordion";
import { ArrowDownIcon, ArrowUpIcon } from "assets/svg";
import { useSearchParams } from "react-router-dom";

const ratings = [4.5, 4, 3.5, 3];
const courseLevels = ["beginner", "intermediate", "advanced"];

const MarketPlaceSideNavAccordion = ({ categories }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const addToParams = (key, value) => {
		if (searchParams.has(key)) {
			searchParams.delete(key);
		}
		searchParams.append(key, value);
		setSearchParams(searchParams);
	};

	return (
		<Accordion allowMultipleExpanded allowZeroExpanded className="accordion">
			<AccordionItem>
				<AccordionItemHeading>
					<AccordionItemButton>
						<div className="flex items-center justify-between mb-2 text-[1.6rem] font-bold  pr-5">
							Ratings
							<AccordionItemState>{({ expanded }) => (expanded ? <ArrowUpIcon /> : <ArrowDownIcon />)}</AccordionItemState>
						</div>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel className="accordion__panel !p-0">
					{ratings.map((rating) => (
						<div className="mb-3" key={rating}>
							<input
								type="radio"
								onChange={() => addToParams("rating", rating)}
								checked={parseFloat(searchParams.get("rating")) === rating}
								className={`mr-3 min-w-[1.5rem] w-6 min-h-[1.5rem] h-6 appearance-none rounded-md border-2 border-gray-200 checked:bg-accent checked:border-accent cursor-pointer`}
								name="ratings"
								value={rating}
							/>
							<StarRatings rating={rating} starRatedColor="#FBBF24" starDimension="15px" starSpacing="1px" />
							<span className="ml-3 text-[1.4rem] font-semibold">{rating}+</span>
						</div>
					))}
				</AccordionItemPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionItemHeading>
					<AccordionItemButton>
						<div className="flex items-center justify-between mb-2 text-[1.6rem] font-bold pr-5">
							Categories
							<AccordionItemState>{({ expanded }) => (expanded ? <ArrowUpIcon /> : <ArrowDownIcon />)}</AccordionItemState>
						</div>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel className="accordion__panel !p-0 max-h-[175px] overflow-scroll">
					{categories?.map((category) => (
						<div className="flex items-center gap-2 mb-3" key={category?.id}>
							<input
								type="radio"
								name="category"
								className={`min-w-[1.5rem] w-6 min-h-[1.5rem] h-6 appearance-none rounded-md border-2 border-gray-200 checked:bg-accent checked:border-accent cursor-pointer`}
								id={category?.id}
								checked={searchParams.get("categoryId") === category.id}
								onChange={() => addToParams("categoryId", category.id)}
							/>
							<label className="text-[1.5rem] capitalize m-0" htmlFor={category?.id}>
								{category?.name}
							</label>
						</div>
					))}
				</AccordionItemPanel>
			</AccordionItem>
			<AccordionItem>
				<AccordionItemHeading>
					<AccordionItemButton>
						<div className="flex items-center justify-between mb-2 text-[1.6rem] font-bold pr-5">
							Course Level
							<AccordionItemState>{({ expanded }) => (expanded ? <ArrowUpIcon /> : <ArrowDownIcon />)}</AccordionItemState>
						</div>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel className="accordion__panel !p-0 max-h-[175px] overflow-scroll">
					{courseLevels?.map((level) => (
						<div className="flex items-center gap-2 mb-3" key={level}>
							<input
								type="radio"
								name="courseLevel"
								className={`min-w-[1.5rem] w-6 min-h-[1.5rem] h-6 appearance-none rounded-md border-2 border-gray-200 checked:bg-accent checked:border-accent cursor-pointer`}
								id={level}
								checked={searchParams.get("courseLevel") === level}
								onChange={() => addToParams("courseLevel", level)}
							/>
							<label className="text-[1.5rem] capitalize m-0" htmlFor={level}>
								{level}
							</label>
						</div>
					))}
				</AccordionItemPanel>
			</AccordionItem>
		</Accordion>
	);
};

export default MarketPlaceSideNavAccordion;

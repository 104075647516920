import React, { useEffect, useState } from "react";
import { CourseDownloads, CourseOverview, CourseReviews, LessonsAccordion, Review, Topnav, Assignment } from "./components";
import { TopNavLayout } from "layouts";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "./css/accordion.css";
import "./css/react-tabs.css";
import { useMediaQuery } from "react-responsive";
import StarRatings from "react-star-ratings";
import { buyCourse, claimCourse, getAllReviews, getCourseBySlug, getMyCourseBySlug, getMyCourses, verifyPayment } from "api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { sanitizeHtmlOption } from "assets/constants";
import sanitizeHtml from "sanitize-html";
import { calculateDiscountPrice, formatNumber, renderCurrency, renderSuccessMessage } from "assets/functions";
import { useAppContext } from "context/AppContext";
import ReactPlayer from "react-player";
import axios from "axios";
import Payment from "context/Payment";
import { ArrowLeftIcon, ArrowRightIcon2, RefreshIcon } from "assets/svg";
import { format, parseISO } from "date-fns";
import LinesEllipsis from "react-lines-ellipsis/lib/html";
import { ReviewsSkeleton } from "skeleton";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import generalClasses from "../general.module.css";

const CourseDetails = () => {
	const { isLoggedIn, user } = useAppContext();
	const { slug, organization } = useParams();

	const { data } = useQuery({
		queryKey: ["my-course", organization],
		queryFn: () => getMyCourses(organization),
		suspense: true,
		enabled: isLoggedIn,
	});

	if (isLoggedIn && user && data?.data?.courses?.some((course) => course?.course?.slug === slug)) {
		return <StatusPaid />;
	} else {
		return <StatusNotPaid />;
	}
};

const StatusPaid = () => {
	const { slug, organization } = useParams();

	const { data, refetch } = useQuery({
		queryKey: ["course", slug, organization],
		queryFn: () => getMyCourseBySlug(slug, organization),
		suspense: true,
	});

	const courseObj = !!data?.data?.courses?.length ? data?.data?.courses[0] : null;
	const course = courseObj?.course;
	const courseLessons = course?.course_sections.flatMap((section) => section.lessons);
	const completedLessons = Array.from(new Set(courseObj?.lessonIDs?.split(",")?.slice(1)));
	const completedLessonNumber = completedLessons?.length;
	const courseLessonNumber = courseLessons?.length;

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

	const [selectedLesson, setSelectedLesson] = useState(
		course?.course_sections
			?.sort((chapter1, chapter2) => chapter1.sectionOrder - chapter2.sectionOrder)
			?.at(0)
			?.lessons?.sort((lesson1, lesson2) => lesson1.lessonOrder - lesson2.lessonOrder)
			?.at(0)
	);
	const [video, setVideo] = useState(null);

	useEffect(() => {
		setVideo("");
		if (selectedLesson && selectedLesson?.mediaUrl) {
			axios
				.get(`https://services.classcube.online/api/v1/media/serve/${selectedLesson?.mediaUrl}`, {
					headers: {
						"x-api-key": process.env.REACT_APP_STREAMING_APIKEY,
					},
				})
				.then((res) => setVideo(res.data.URL));
		}
	}, [selectedLesson]);

	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>ClassCube Enterprise | {course?.name} </title>
			</Helmet>
			<div className="flex flex-col px-size">
				<Topnav course={course} percentage={(completedLessonNumber / courseLessonNumber) * 100} />
				<div className="mx-auto max-w-[calc(1440px-8vw)] w-full">
					<div className="grid grid-cols-3 gap-16 md:mb-20">
						{video ? (
							<div className="col-span-2 max-[768px]:col-span-3 min-h-[40vh] max-h-[55vh] flex justify-center items-start">
								<ReactPlayer
									url={video}
									controls
									config={{
										file: {
											attributes: {
												controlsList: "nodownload",
											},
										},
									}}
									width={"100%"}
									height={"100%"}
									playsinline
									// Disable right click
									onContextMenu={(e) => e.preventDefault()}
								/>
							</div>
						) : (
							<div className="col-span-2 max-[768px]:col-span-3 min-h-[40vh] max-h-[55vh] flex justify-center items-start overflow-hidden">
								<img
									src={`https://${course?.courseImg}`}
									alt=""
									style={{
										width: "100%",
										height: "60dvh",
										objectFit: "cover",
									}}
								/>
							</div>
						)}
						<div className="overflow-scroll  max-h-[55vh] max-[768px]:hidden">
							<LessonsAccordion
								lessons={course?.course_sections}
								setSelectedLesson={setSelectedLesson}
								selectedLesson={selectedLesson}
								refetch={refetch}
								completedLessons={completedLessons}
							/>
						</div>
					</div>
					<div className="mt-[20px]">
						<Tabs>
							<TabList className="react-tabs__tab-list flex-wrap gap-y-[10px]">
								{isTabletOrMobile && <Tab>Course Content</Tab>}
								{selectedLesson?.content && <Tab>Notes</Tab>}
								{selectedLesson?.attachment.length > 0 && selectedLesson?.attachment[0] !== "" && <Tab>Downloads</Tab>}
								{!!selectedLesson?.assignments.length && <Tab>Assignments</Tab>}
								<Tab>Reviews</Tab>
							</TabList>

							{isTabletOrMobile && (
								<TabPanel>
									<LessonsAccordion
										lessons={course?.course_sections}
										setSelectedLesson={setSelectedLesson}
										selectedLesson={selectedLesson}
										refetch={refetch}
										completedLessons={completedLessons}
									/>
								</TabPanel>
							)}
							{selectedLesson?.content && (
								<TabPanel>
									<div
										className="text-grey text-[1.6rem] font-normal mb-10 text-justify"
										dangerouslySetInnerHTML={{
											__html: sanitizeHtml(selectedLesson?.content, sanitizeHtmlOption),
										}}
									/>
								</TabPanel>
							)}
							{selectedLesson?.attachment.length > 0 && selectedLesson?.attachment[0] !== "" && (
								<TabPanel>
									<CourseDownloads selectedLesson={selectedLesson} />
								</TabPanel>
							)}
							{!!selectedLesson?.assignments.length && (
								<TabPanel>
									<Assignment assignment={selectedLesson?.assignments} course={course} />
								</TabPanel>
							)}
							<TabPanel>
								<CourseReviews courseId={course?.id} />
							</TabPanel>
						</Tabs>
					</div>
				</div>
			</div>
		</div>
	);
};

const StatusNotPaid = () => {
	const { isLoggedIn } = useAppContext();
	const [enabled, setEnabled] = useState(false);
	const [enrollEnabled, setEnrollEnabled] = useState(false);
	const [veriyPaymentEnabled, setVeriyPaymentEnabled] = useState(false);
	const [monnifyRes, setMonnifyRes] = useState();
	const { slug, organization } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page") || 1;
	const limit = 10;

	const navigate = useNavigate();

	const { data } = useQuery({
		queryKey: ["course", slug, organization],
		queryFn: () => getCourseBySlug(slug, organization),
		suspense: true,
	});
	const { isLoading, data: reviews } = useQuery({
		queryKey: ["reviews", data?.data?.course?.id, organization, page, limit],
		queryFn: () => getAllReviews(data?.data?.course?.id, organization, page, limit),
	});

	useQuery({
		queryKey: ["claimCourse", data?.data?.course?.id, organization],
		queryFn: () => claimCourse(data?.data?.course?.id, organization),
		enabled: enrollEnabled,
		onSuccess: (res) => {
			renderSuccessMessage(res?.message).then(() => {
				window.location.reload(true);
			});
		},
		onError: (err) => {
			setEnrollEnabled(false);
		},
	});

	const course = data?.data?.course;
	const rating = data?.data?.review;

	useQuery({
		queryKey: ["paymentInfo", data?.data?.course?.id, organization],
		queryFn: () => buyCourse(data?.data?.course?.id, organization),
		enabled: enabled,
		onSuccess: (res) => {
			Payment({
				data: res?.paymentParams,
				onComplete: (res) => {
					if (res.status === "SUCCESS") {
						setMonnifyRes(res);
						setVeriyPaymentEnabled(true);
					}
				},
				onClose: (res) => {
					setEnabled(false);
					return;
				},
			});
		},
	});

	useQuery({
		queryKey: ["verifyPayment", organization],
		queryFn: () => verifyPayment(monnifyRes, organization),
		enabled: veriyPaymentEnabled,
		onSuccess: (res) => {
			setVeriyPaymentEnabled(false);
			renderSuccessMessage(res?.message).then(() => {
				window.location.reload(true);
			});
		},
	});

	const makePayment = () => {
		if (isLoggedIn) {
			if (!!calculateDiscountPrice(course?.price, parseInt(course?.discount ?? 0))) {
				setEnabled(true);
			} else {
				setEnrollEnabled(true);
			}
		} else {
			navigate(`/${organization}/login?redirect=${slug}`);
		}
	};

	const pageCount = Math.ceil(data?.data?.totalReviews / limit);

	const handlePageClick = ({ selected }) => {
		searchParams.set("page", selected + 1);
		setSearchParams(searchParams);
	};

	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>ClassCube Enterprise | {course?.name} </title>
			</Helmet>
			<TopNavLayout />
			<div className="bg-[#EEF6FF] min-[992px]:p-[50px] py-[2.5rem] px-[1rem] min-[992px]:h-[350px]">
				<div className="row max-w-[1400px] mx-auto">
					<div className="col-lg-8">
						<h1 className="buy-course-heading">{course?.name}</h1>
						<LinesEllipsis
							unsafeHTML={sanitizeHtml(course?.description, sanitizeHtmlOption)}
							className="text-grey text-[1.6rem] max-w-[90%] font-normal mb-3 text-justify"
							maxLine="2"
							ellipsis="..."
							basedOn="letters"
						/>

						<div className="flex items-center gap-4">
							<p className="text-[#E59819] text-[1.4rem] font-medium leading-none mt-1 ">
								{rating?.averageRating ? rating?.averageRating.toFixed(1) : (0).toFixed(1)}
							</p>
							<StarRatings
								rating={rating?.averageRating ? rating?.averageRating : 0}
								starRatedColor="#FBBF24"
								starDimension="15px"
								starSpacing="3px"
							/>
						</div>
						<p className="mt-[2rem] text-[1.4rem] text-grey">
							Created by:
							<span className="ml-2 font-semibold text-grey ">{course?.creator?.fullName}</span>
						</p>
						<p className="text-grey text-[1.4rem] font-normal mt-[2rem] items-center flex gap-3">
							<span>
								<RefreshIcon width={15} />
							</span>
							Last Updated:
							<span>{course?.updatedAt ? format(parseISO(course?.updatedAt), "PPP") : ""}</span>
						</p>
					</div>
					<div className="col-lg-4 max-[992px]:mt-[30px] max-[992px]:flex">
						<div className="bg-white shadow-lg max-[992px]:w-[60%] min-w-[300px] mx-auto">
							<img src={`https://${course?.courseImg}`} alt="course" className="w-full" />
							<div className="px-[20px] pt-[15px] pb-[30px] ">
								{/* <div className="p-2 mb-4 border-2 border-solid rounded-md focus-within:border-primary buy-course-banner">
									<input
										className="w-[80%] border-none outline-none"
										placeholder="Discount code"
										type="text"
									/>
									<button className="w-[20%] rounded-md  ml-3 border-solid border-2 p-2 text-[1.2rem] text-[#334155]">
										Apply
									</button>
								</div> */}
								<div className="buy-course-banner mt-[25px]">
									<p>Order Total</p>
									{!!course?.discount && !!parseInt(course?.discount) && !!course?.price ? (
										<>
											<div className="flex flex-row items-center gap-3">
												<p className="font-bold text-[1.8rem]">
													{renderCurrency(calculateDiscountPrice(course?.price, parseInt(course?.discount ?? 0)))}
												</p>
												<p className="line-through text-[1.4rem] text-grey">{renderCurrency(course?.price)}</p>
											</div>
										</>
									) : (
										<p className="font-bold text-[1.8rem]">{renderCurrency(course?.price)}</p>
									)}
								</div>
								{!!calculateDiscountPrice(course?.price, parseInt(course?.discount ?? 0)) ? (
									<button
										className="!bg-primary text-white p-[10px] w-full mt-[20px] buyBtn rounded-lg"
										onClick={() => makePayment()}
										disabled={enabled}
									>
										Buy Now
									</button>
								) : (
									<button
										className="!bg-primary text-white p-[10px] w-full mt-[20px] buyBtn rounded-lg"
										onClick={() => makePayment()}
										disabled={enrollEnabled}
									>
										Enroll Now
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="min-[768px]:p-[50px] p-[25px] max-w-[1440px] flex flex-col mx-auto">
				<CourseOverview course={course} />
				{isLoading ? (
					<ReviewsSkeleton count={5} />
				) : (
					<div className="pt-8 max-w-full md:max-w-[650px] lg:max-w-[900px]">
						<div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] gap-10 mb-20">
							<div>
								<h3 className="text-black text-[2rem] font-bold mb-4">Total Reviews</h3>
								<p className="text-grey text-[2.6rem] font-bold mb-3">{formatNumber(reviews?.data?.totalReviews)}</p>
							</div>
							<div>
								<h3 className="text-black text-[2rem] font-bold mb-4">Average Rating</h3>
								<div className="flex items-center gap-4 mb-3">
									<p className="text-grey text-[2.6rem] font-bold">{reviews?.data?.averageRating.toFixed(1)}</p>
									<StarRatings
										rating={reviews?.data?.averageRating}
										starRatedColor="#FBBF24"
										starDimension="25px"
										starSpacing="3px"
									/>
								</div>
							</div>
						</div>

						{!!parseInt(reviews?.data?.courseReviews.length) ? (
							<h3 className="text-black font-bold text-[2rem] mb-5">Reviews</h3>
						) : (
							<>
								<div className="flex flex-col items-center justify-center gap-5">
									<p className="text-grey text-[1.7rem] md:max-w-[705px] font-medium">There are no reviews.</p>
								</div>
							</>
						)}

						{reviews?.data?.courseReviews?.map((review, index) => (
							<Review key={index} data={review} />
						))}

						{!!parseInt(reviews?.data?.courseReviews.length) && (
							<ReactPaginate
								nextLabel={
									<span className="flex items-center gap-1">
										Next <ArrowRightIcon2 />
									</span>
								}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pageCount}
								previousLabel={
									<span className="flex items-center gap-1">
										<ArrowLeftIcon /> Previous{" "}
									</span>
								}
								pageClassName="page-item"
								pageLinkClassName={generalClasses.pageLink}
								previousClassName="page-item"
								previousLinkClassName={generalClasses.pageLink}
								nextClassName="page-item"
								nextLinkClassName={generalClasses.pageLink}
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName={generalClasses.pageLink}
								containerClassName={generalClasses.pagination}
								activeClassName={generalClasses.active}
								renderOnZeroPageCount={null}
								forcePage={parseInt(searchParams.get("page") || 1) - 1}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default CourseDetails;

import React from "react";
import logo from "assets/logo-inverted.png";
import { Link, useParams } from "react-router-dom";
import { useAppContext } from "context/AppContext";

const AppFooter = () => {
	const { isLoggedIn } = useAppContext();
	const { organization } = useParams();

	return (
		<footer className="flex px-[3rem] flex-col gap-10 app-footer">
			<div className="footer-div">
				<div className="c-footer flex gap-40 flex-wrap text-[1.6rem] font-normal mb-16">
					<ul className="flex flex-col gap-3">
						<li>
							<Link to="https://classcube.online/contact" target="_blank">
								Contact Us
							</Link>
						</li>
						<li>
							<Link to="https://classcube.online/privacy" target="_blank">
								Privacy Policy
							</Link>
						</li>
						<li>
							<Link to="#">Terms & Conditions</Link>
						</li>
					</ul>
					<ul className="flex flex-col gap-3">
						<li>
							{isLoggedIn && (
								<li>
									<Link to="/my-courses">My Courses</Link>
								</li>
							)}
						</li>
						<li>
							<Link to={`/creator/${organization}/signup`} target="_blank" rel="noopener noreferrer">
								Become a Creator
							</Link>
						</li>
						<li>
							<Link to="https://classcube.online/contact" target="_blank">
								Help & Support
							</Link>
						</li>
					</ul>
				</div>
				<div className="flex flex-wrap items-center justify-between gap-4 mt-[10px]">
					<img src={logo} className="w-[115px]" alt="logo" />
					<p className="text-[1.6rem] font-normal">Copyright © {new Date().getFullYear()} Revocube. All Rights Reserved</p>
				</div>
			</div>
		</footer>
	);
};
export default AppFooter;

import { decrypt } from "assets/functions";

const Payment = ({ data, onComplete, onClose }) => {
	switch (data?.type) {
		case "Monnify":
			return window.MonnifySDK.initialize({
				amount: data?.amount,
				currency: data?.currency,
				reference: data?.transId,
				customerName: data?.customerFullName,
				customerEmail: data?.customerEmail,
				apiKey: decrypt(data?.apiKey),
				contractCode: decrypt(data?.contractCode),
				paymentDescription: data?.paymentDescription,
				isTestMode: data?.mode,
				incomeSplitConfig: data?.subAcct?.map(({ percentage, subAccount }) => ({
					subAccountCode: subAccount,
					feePercentage: 0,
					splitPercentage: percentage,
					feeBearer: false,
				})),
				onComplete: onComplete,
				onClose: onClose,
			});

		case "Paystack":
			break;

		case "Flutterwave":
			break;

		default:
	}
};

export default Payment;

import React, { useEffect, useState } from "react";
import logo from "assets/logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SearchIcon } from "assets/svg";
import { useAppContext } from "context/AppContext";
import { CustomInput } from "components";
import { BiBell, BiMenuAltLeft, BiSearch, BiX } from "react-icons/bi";
import { generateProfileImage } from "assets/functions";
import { useCookies } from "react-cookie";

const AppHeader = () => {
	const { isLoggedIn, homepage, user } = useAppContext();
	const { organization } = useParams();
	const navigate = useNavigate();
	const [, , removeCookie] = useCookies();

	const [isMobile, setIsMobile] = useState(false);
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [isNavOpen, setIsNavOpen] = useState(false);
	const [search, setSearch] = useState("");

	const profileImage = generateProfileImage(user?.firstName, user?.lastName);

	useEffect(() => {
		const handleResize = () => {
			const mediaQuery = window.matchMedia("(max-width: 865px)");
			setIsMobile(mediaQuery.matches);
		};

		// Initial check on component mount
		handleResize();

		// Add event listener for window resize
		window.addEventListener("resize", handleResize);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleLogout = () => {
		removeCookie("jwt");
		localStorage.clear();
		window.location.reload();
	};

	//not login
	if (!isLoggedIn && isMobile) {
		return (
			<div>
				<div className="flex items-center justify-between gap-6 py-4 px-[3rem] max-w-[1440px] w-full mx-auto">
					<BiMenuAltLeft fontSize={25} className="text-grey" onClick={() => setIsNavOpen(!isNavOpen)} />

					<Link to={`/${organization}/`}>
						<img src={homepage?.logo ? `https://${homepage?.logo}` : logo} className="w-[120px] max-h-[60px] object-contain" alt="Logo" />
					</Link>

					<BiSearch fontSize={25} className="text-grey" onClick={() => setIsSearchOpen(!isSearchOpen)} />

					{isSearchOpen && (
						<div className="fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-50">
							<div className="w-full bg-white ">
								<div className="flex items-center border border-gray-300 ">
									<input
										type="text"
										placeholder="Search..."
										className="w-full px-4 py-4 focus:outline-none text-[1.4rem] text-grey"
										value={search}
										onChange={(event) => setSearch(event.target.value)}
										onKeyDown={(event) => event.keyCode === 13 && search && navigate(`/${organization}/courses?search=${search}`)}
									/>
									<BiX fontSize={28} className="mr-4 text-grey" onClick={() => setIsSearchOpen(!isSearchOpen)} />
								</div>
							</div>
						</div>
					)}
				</div>

				{isNavOpen && (
					<div
						className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform ${
							isNavOpen ? "translate-x-0" : "-translate-x-full"
						} ease-in-out duration-300`}
					>
						<div className="w-[45%] bg-white h-full">
							<div className="flex justify-end px-3 py-2 ">
								<BiX fontSize={25} className="cursor-pointer text-grey " onClick={() => setIsNavOpen(!isNavOpen)} />
							</div>

							<div className="border-b-[1px] py-4 px-5 flex flex-col">
								<Link to={`/${organization}/login`} className="text-accent hover:underline text-[1.4rem] mb-4">
									Log in
								</Link>
								{homepage?.allowStudentReg && (
									<Link to={`/${organization}/register`} className="!text-primary hover:underline text-[1.4rem] mb-3">
										Sign up
									</Link>
								)}
							</div>
							<div className="flex flex-col px-5 py-4">
								<Link to={`/${organization}/courses`} className="text-grey hover:underline hover:text-accent text-[1.4rem] mb-4">
									MarketPlace
								</Link>
								{homepage?.allowCreatorReg && (
									<Link
										to={`/creator/${organization}/signup`}
										target="_blank"
										rel="noopener noreferrer"
										className="text-grey hover:underline hover:text-accent text-[1.4rem]"
									>
										Become a Creator
									</Link>
								)}
								<Link
									to="https://classcube.online/contact"
									target="_blank"
									className="text-grey hover:underline hover:text-accent text-[1.4rem] mb-4"
								>
									Help
								</Link>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}

	//login
	if (isLoggedIn && user && isMobile) {
		return (
			<div>
				<div className="flex items-center justify-between gap-6 py-4 px-[3rem] max-w-[1440px] w-full mx-auto">
					<BiMenuAltLeft fontSize={25} className="text-grey" onClick={() => setIsNavOpen(!isNavOpen)} />

					<Link to={`/${organization}/`} className="w-[120px]">
						<img src={homepage?.logo ? `https://${homepage?.logo}` : logo} className=" max-h-[60px] object-contain" alt="Logo" />
					</Link>

					<BiSearch fontSize={25} className="text-grey" onClick={() => setIsSearchOpen(!isSearchOpen)} />

					{isSearchOpen && (
						<div className="fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-50">
							<div className="w-full bg-white ">
								<div className="flex items-center border border-gray-300 ">
									<input
										type="text"
										placeholder="Search..."
										className="w-full px-4 py-4 focus:outline-none text-[1.4rem] text-grey"
										value={search}
										onChange={(event) => setSearch(event.target.value)}
										onKeyDown={(event) => event.keyCode === 13 && search && navigate(`/${organization}/courses?search=${search}`)}
									/>
									<BiX fontSize={28} className="mr-4 text-grey" onClick={() => setIsSearchOpen(!isSearchOpen)} />
								</div>
							</div>
						</div>
					)}
				</div>

				{isNavOpen && (
					<div
						className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform ${
							isNavOpen ? "translate-x-0" : "-translate-x-full"
						} ease-in-out duration-300`}
					>
						<div className="w-[60%] md:w-[40%] bg-white h-full">
							<div className="flex justify-between px-3 py-3 bg-[#f7f9fa] ">
								<div className="flex items-center gap-5 px-4 py-2 ">
									{profileImage}
									<p className="text-gray text-[1.6rem] font-semibold">
										Hi,{user?.firstName} {user?.lastName}
										<br />
										<span className=" text-[1.2rem] font-500">Welcome</span>
									</p>
								</div>
								<BiX fontSize={25} className="cursor-pointer text-grey " onClick={() => setIsNavOpen(!isNavOpen)} />
							</div>

							<div className="border-b-[1px] py-4 px-5 flex flex-col">
								<Link to={`/${organization}/settings`} className="text-grey hover:underline text-[1.4rem] mb-4">
									Account Settings
								</Link>
								<Link to={`/${organization}/notifications`} className="text-grey hover:underline text-[1.4rem] mb-4">
									Notifications
								</Link>
								<Link to={`/${organization}/my-courses`} className="text-grey hover:underline text-[1.4rem] mb-4">
									My Courses
								</Link>
								<Link to={`/${organization}/courses`} className="text-grey hover:underline text-[1.4rem] mb-4">
									MarketPlace
								</Link>
							</div>
							<div className="border-b-[1px] py-4 px-5 flex flex-col">
								{homepage?.allowCreatorReg && (
									<Link
										to={`/creator/${organization}/signup`}
										target="_blank"
										rel="noopener noreferrer"
										className="text-grey hover:underline hover:text-accent text-[1.4rem]"
									>
										Become a Creator
									</Link>
								)}
								<Link
									to="https://classcube.online/contact"
									target="_blank"
									className="text-grey hover:underline hover:text-accent text-[1.4rem] mb-4"
								>
									Help
								</Link>
							</div>
							<div className="flex flex-col items-start px-5 py-4">
								<button className="text-accent hover:underline hover:text-accent text-[1.4rem] mb-4" onClick={handleLogout}>
									Logout
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}

	if (isLoggedIn && user)
		return (
			<div className="the-header">
				<div className="py-[2rem] px-[3rem] flex items-center justify-between gap-6 max-w-[1440px] w-full mx-auto">
					<Link to={`/${organization}/`} className="flex items-center gap-6">
						<img
							src={homepage?.logo ? `https://${homepage?.logo}` : logo}
							className="w-[100px] md:w-[120px] lg:w-[160px] max-h-[60px] object-contain"
							alt="Logo"
						/>
					</Link>

					<CustomInput
						placeholder="Search for courses.."
						leftIcon={<SearchIcon />}
						className="nav-search-input"
						value={search}
						handleChange={setSearch}
						onKeyDown={(event) => event.keyCode === 13 && search && navigate(`/${organization}/courses?search=${search}`)}
					/>
					<div className="flex items-center gap-6">
						<Link to={`/${organization}/my-courses`} className="text-black text-[1.6rem]">
							My Courses
						</Link>
						<Link to={`/${organization}/notifications`}>
							<BiBell fontSize={25} />
						</Link>
						<Link to="#" onClick={() => setIsNavOpen(!isNavOpen)}>
							{profileImage}
						</Link>
					</div>
				</div>
				{isNavOpen && (
					<div className="relative">
						<div
							className={`flex justify-end fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform ${
								isNavOpen ? "translate-x-0" : "-translate-x-full"
							} ease-in-out duration-300`}
						>
							<div className="w-[40%] bg-white h-full">
								<div className="flex justify-between px-4 py-5 border-b-[1px] ">
									<div className="flex items-center gap-5 ">
										{profileImage}
										<p className="text-gray text-[1.6rem] font-semibold">
											Hi, {user?.firstName} {user?.lastName}
											<br />
											<span className=" text-[1.2rem] font-500">Welcome</span>
										</p>
									</div>
									<BiX fontSize={25} className="cursor-pointer text-grey " onClick={() => setIsNavOpen(!isNavOpen)} />
								</div>

								<div className="border-b-[1px] py-4 px-5 flex flex-col">
									<Link to={`/${organization}/settings`} className="text-grey hover:underline text-[1.4rem] mb-4">
										Account Settings
									</Link>
									<Link to={`/${organization}/notifications`} className="text-grey hover:underline text-[1.4rem] mb-4">
										Notifications
									</Link>
									<Link to={`/${organization}/my-courses`} className="text-grey hover:underline text-[1.4rem] mb-4">
										My Courses
									</Link>
									<Link to={`/${organization}/courses`} className="text-grey hover:underline text-[1.4rem] mb-4">
										MarketPlace
									</Link>
								</div>
								<div className="border-b-[1px] py-4 px-5 flex flex-col">
									{homepage?.allowCreatorReg && (
										<Link
											to={`/creator/${organization}/signup`}
											target="_blank"
											rel="noopener noreferrer"
											className="text-grey hover:underline hover:text-accent text-[1.4rem]"
										>
											Become a Creator
										</Link>
									)}
									<Link
										to="https://classcube.online/contact"
										target="_blank"
										className="text-grey hover:underline hover:text-accent text-[1.4rem] mb-4"
									>
										Help
									</Link>
								</div>
								<div className="flex flex-col items-start px-5 py-4">
									<button className="text-accent hover:underline hover:text-accent text-[1.4rem] mb-4" onClick={handleLogout}>
										Logout
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);

	return (
		<div className="the-header">
			<div className="py-[2rem] px-[3rem] flex items-center justify-between gap-6 max-w-[1440px] w-full mx-auto">
				<Link to={`/${organization}/`}>
					<img
						src={homepage?.logo ? `https://${homepage?.logo}` : logo}
						className="w-[100px] md:w-[120px] lg:w-[160px] max-h-[60px] object-contain"
						alt="Logo"
					/>
				</Link>
				<CustomInput
					placeholder="Search for courses.."
					leftIcon={<SearchIcon onClick={() => search && navigate(`/${organization}/courses?search=${search}`)} />}
					className="nav-search-input"
					value={search}
					handleChange={setSearch}
					onKeyDown={(event) => event.keyCode === 13 && search && navigate(`/${organization}/courses?search=${search}`)}
				/>
				<div className="flex items-center gap-6">
					{homepage?.allowCreatorReg && (
						<Link
							to={`/creator/${organization}/signup`}
							target="_blank"
							rel="noopener noreferrer"
							className="text-grey hover:underline hover:text-accent text-[1.4rem]"
						>
							Become a Creator
						</Link>
					)}
					<Link to={`/${organization}/login`} className="bg-accent text-white text-[1.4rem] py-[10px] px-[15px] rounded-md">
						Log in
					</Link>
					{homepage?.allowStudentReg && (
						<Link to={`/${organization}/register`} className="!bg-primary text-white text-[1.4rem] py-[10px] px-[15px] rounded-md">
							Sign up
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export default AppHeader;

import React from "react";
import Dropzone from "./Dropzone";
import { ErrorMessage, Formik } from "formik";
import CustomEditor from "../../../components/CustomEditor";
import { MAX_FILE_SIZE, sanitizeHtmlOption, validFileExtensions } from "assets/constants";
import sanitizeHtml from "sanitize-html";
import * as Yup from "yup";
import { isValidFileType, renderSuccessMessage } from "assets/functions";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getSubmissionState, submitAssignment } from "api";
import { LessonAttachement } from "./CourseDownloads";
import Loader from "components/Loader";
import { useParams } from "react-router-dom";

const Assignment = ({ assignment, course }) => {
	const { organization } = useParams();

	const assignmentDetails = assignment[0];

	const initialValues = {
		courseId: course?.id,
		lessonId: assignmentDetails.lessonId,
		assignmentId: assignmentDetails.id,
		content: "",
		attachment: [],
	};
	const validationSchema = Yup.object().shape({
		content: Yup.string(),
		attachment: Yup.array()
			.min(0, "You must provide at least 1 attachment")
			.test("is-valid-type", `Not a valid file type, files must be either ${validFileExtensions.attachments.join(", ")}`, (value) => {
				return value.every((file) => isValidFileType(file && file.name.toLowerCase(), "attachments"));
			})
			.test("is-valid-size", "Max allowed size any file is 350MB", (value) => {
				return value.every((file) => file && file.size <= MAX_FILE_SIZE);
			}),
	});

	const { mutate, isLoading } = useMutation(submitAssignment, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => {}),
	});

	const { data, isLoading: isSubmitLoading } = useQuery({
		queryKey: ["submitted", assignmentDetails.id, organization],
		queryFn: () => getSubmissionState(assignmentDetails.id, organization),
	});

	if (isSubmitLoading) return <Loader />;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				let data = { ...values };

				let formData = new FormData();
				Object.entries(data).forEach(([key, value]) => {
					if (Array.isArray(value)) {
						value.forEach((item) => {
							formData.append(key, item);
						});
					} else {
						formData.append(key, value);
					}
				});

				mutate({ data: formData, organization });
			}}
		>
			{({ setFieldValue, handleSubmit }) => (
				<form className="flex flex-col" onSubmit={handleSubmit}>
					<h2 className="text-[1.8rem] mb-[15px] font-bold text-grey">Questions and Attachments</h2>
					<div
						className="text-grey text-[1.6rem] font-normal mb-10 text-justify"
						dangerouslySetInnerHTML={{
							__html: sanitizeHtml(assignmentDetails?.content, sanitizeHtmlOption),
						}}
					/>
					{!!assignmentDetails?.attachment && <LessonAttachement attachment={assignmentDetails?.attachment} />}
					{!data?.data?.submitted && (
						<>
							<h2 className="text-[1.8rem] my-[15px] font-bold text-grey">Your response</h2>
							<CustomEditor setState={setFieldValue} name="content" />
							<Dropzone multiple={false} name="attachment" />
							<ErrorMessage name="attachment" component="div" className="text-red-500 text-[1rem]" />
							<button type="submit" className="btn-form-primary" disabled={isLoading}>
								Submit
							</button>
						</>
					)}
				</form>
			)}
		</Formik>
	);
};
export default Assignment;

import React from 'react'

import { ReactComponent as Clock } from '../../assets/svg/clock2.svg'
import { ReactComponent as Shield } from '../../assets/svg/shield.svg'
import { ReactComponent as Mouse } from '../../assets/svg/mouse-square.svg'

const Support = () => {
    return (
        <section className='Support container mx-auto mt-40'>
            <div className="pt-20">
                <h1 className='w-full md:w-[40%] text-6xl font-semibold leading-tight text-darkBlue mb-7'>Be in Control of Your Online Education Platform</h1>
                <p className='w-full md:w-[40%] text-gray text-main'>Your best solution for promoting professional growth and a continuous learning culture within your organization.</p>
            </div>
            <div className="Support-info mt-32 bg-[#0C78F1] container flex flex-col md:flex-row gap-44 mb-96 p-24 md:p-36 md:py-28 rounded-3xl">
                <div className="support--1">
                    <Clock className='mb-7'/>
                    <h1 className='text-4xl font-[600] text-[#FDE5C3] mb-7'>24/7 support</h1>
                    <p className='text-white'>Our platform and team is committed to supporting corporate organizations in utilizing Classcube Enterprise.</p>
                </div>
                <div className="support--2">
                    <Shield className='mb-7' />
                    <h1 className='text-4xl font-[600] text-[#FDE5C3] mb-7'>Security and Data Privacy</h1>
                    <p className='text-white'>The platform’s robust security measures and adherence to data privacy regulations, demonstrates the safety of your company’s valuable training data and information.</p>
                </div>
                <div className="support--3">
                    <Mouse className='mb-7' />
                    <h1 className='text-4xl font-[600] text-[#FDE5C3] mb-7'>User-friendly Interface</h1>
                    <p className='text-white'>Our learning management platform prioritizes user experience, providing an exceptionally user-friendly interface that empowers learners to navigate, engage, and excel effortlessly</p>
                </div>
            </div>
        </section>
    )
}

export default Support
import React from "react";

import { ReactComponent as Pen } from "../../assets/svg/pen-down.svg";
import { ReactComponent as Person } from "../../assets/svg/person.svg";
import { ReactComponent as Heart } from "../../assets/svg/heart.svg";
import { ReactComponent as Folder } from "../../assets/svg/setting-folder.svg";

// import frame1 from '../../assets/Frame 736.png'

const Benefits = () => {
	return (
		<section className="mt-72 Benefits" id="Benefits" style={{ scrollMarginTop: 90 }}>
			<div className="container pt-20 text-center">
				<h1 className="w-full md:w-[40%] text-6xl font-semibold mx-auto leading-tight text-darkBlue mb-7">
					Explore ClassCube Enterprise Benefits for Your Business
				</h1>
				<p className="w-full md:w-[48%] text-gray mx-auto text-main">
					Unleash the full potential of your workforce by creating a learning environment tailored precisely
					to your needs, objectives, and brand identity
				</p>
			</div>

			<div className="container flex flex-col gap-16 mt-40 Benefits md:flex-row mb-96">
				<div className="Benefit">
					<Pen className="mb-7" />
					<h1 className="font-[600] text-darkBlue mb-7">Branding and Customization</h1>
					<p className="text-gray text-main">
						Tailor the platform with your company’s logo, color schemes, and branding elements, fostering a
						sense of ownership and alignment among your employees.
					</p>
				</div>
				<div className="Benefit">
					<Folder className="mb-7" />
					<h1 className="font-[600] text-darkBlue mb-7">Content Library Management</h1>
					<p className="text-gray text-main">
						Organize and curate a personalized content library, offering courses and resources that align
						with your company’s specific goals and objectives.
					</p>
				</div>
				<div className="Benefit">
					<Heart className="mb-7" />
					<h1 className="font-[600] text-darkBlue mb-7">Collaborative Content</h1>
					<p className="text-gray text-main">
						Partner with renowned industry professionals and a diverse range of perspectives and knowledge
						to your learning content.
					</p>
				</div>
				<div className="Benefit">
					<Person className="mb-7" />
					<h1 className="font-[600] text-darkBlue mb-7">Admin Controls and User Roles</h1>
					<p className="text-gray text-main">
						Assign roles and permissions to your team, giving you full control over content, course access,
						and user management.
					</p>
				</div>
			</div>
		</section>
	);
};

export default Benefits;

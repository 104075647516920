import React, { useState } from "react";
import logo from "assets/logo.png";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import "react-phone-number-input/style.css";
import { ErrorMessage, Formik } from "formik";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import { renderErrorMessage, renderSuccessModal } from "assets/functions";
import { Helmet } from "react-helmet";
import register from "assets/register.png";
import { orgsignup } from "api";

const FormSchema = yup.object().shape({
	fullName: yup.string().required("Please provide your full name"),
	organisation_Name: yup.string().required("Please provide company name"),
	email: yup.string().email("Please provide a valid email").required("Please provide a valid email"),
	password: yup
		.string()
		.required("Password is required")
		.min(8, "Password must be 8 characters long")
		.matches(/[0-9]/, "Password requires a number")
		.matches(/[a-z]/, "Password requires a lowercase letter")
		.matches(/[A-Z]/, "Password requires an uppercase letter")
		.matches(/[^\w]/, "Password requires a symbol"),
	password1: yup
		.string()
		.required("Confirm your password")
		.oneOf([yup.ref("password"), null], "Passwords don't match"),
});

const initialValues = {
	fullName: "",
	email: "",
	password: "",
	password1: "",
	organisation_Name: "",
};

const OrganizationSignUp = () => {
	const [visible, setVisible] = useState(false);
	const [visible1, setVisible1] = useState(false);

	const { mutate, isLoading } = useMutation(orgsignup, {
		onSuccess: (data) => {
			renderSuccessModal(data?.message);
		},
		onError: (error) => renderErrorMessage(error.message),
	});

	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Classcube Enterprise | Register</title>
			</Helmet>
			<div className="row justify-center align-center  h-[100vh]">
				<div className="h-full scrollmenot col-lg-6">
					<div className="flex items-center justify-center">
						<div className=" mt-[100px] w-[100%]">
							<div className="flex items-center justify-center">
								<img src={logo} className="w-[160px]  h-[40px]" alt="ClassCube Enterprise" />
							</div>

							<h1 className="text-[#0372CE] text-center text-[32px]  font-bold mb-[15px] mt-[20px]">Create Your Business Account</h1>
							<p className="text-[#5E6C85] text-center text-[2rem] font-normal">Please enter your details to access your account.</p>

							<div className=" flex justify-center align-center mt-[50px]">
								<Formik
									initialValues={initialValues}
									validationSchema={FormSchema}
									onSubmit={({ password1, ...values }) => {
										mutate(values)
									}}
								>
									{({ values, handleChange, handleSubmit }) => (
										<form className="w-[90%] max-w-[500px] mb-[100px]" onSubmit={handleSubmit}>
											<div className="form-group ">
												<label className="font-[500px] text-[1.4rem] text-black">Fullname</label>
												<input
													type="text"
													className="control-form border color-[#F2F4F7]"
													placeholder="Enter your first name"
													name="fullName"
													onChange={handleChange}
													value={values.fullName}
												/>
												<ErrorMessage name="fullName" component="div" className="text-red-500" />
											</div>
											<div className="form-group ">
												<label className="font-[500px] text-[1.4rem] text-black">Company Name</label>
												<input
													type="text"
													className="control-form border color-[#F2F4F7]"
													placeholder="Enter Company Name"
													name="organisation_Name"
													onChange={handleChange}
													value={values.organisation_Name}
												/>
												<ErrorMessage name="organisation_Name" component="div" className="text-red-500" />
											</div>
											<div className="form-group">
												<label className="font-[500px] text-[1.4rem] text-black">Company Email Address</label>
												<input
													type="text"
													className="control-form border color-[#F2F4F7]"
													placeholder="Enter your Email Address"
													name="email"
													onChange={handleChange}
													value={values.email}
												/>
												<ErrorMessage name="email" component="div" className="text-red-500" />
											</div>
											<div className="form-group">
												<label className="font-[500px] text-[1.4rem] text-black">Password</label>
												<div className="has-pwd-icon">
													<input
														className="control-form border color-[#F2F4F7]"
														placeholder="Type your password"
														onChange={handleChange}
														type={visible ? "text" : "password"}
														name="password"
														value={values.password}
													/>
													{visible ? (
														<BsEye className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
													) : (
														<BsEyeSlash
															className="view-pwd"
															size={20}
															onClick={() => setVisible((prevState) => !prevState)}
														/>
													)}
												</div>
												<ErrorMessage name="password" component="div" className="text-red-500" />
											</div>
											<div className="form-group">
												<label className="font-[500px] text-[1.4rem] text-black">Confirm Password</label>
												<div className="has-pwd-icon">
													<input
														className="control-form border color-[#F2F4F7]"
														placeholder="Re-type your password"
														onChange={handleChange}
														type={visible1 ? "text" : "password"}
														name="password1"
														value={values.password1}
													/>
													{visible1 ? (
														<BsEye
															className="view-pwd"
															size={20}
															onClick={() => setVisible1((prevState) => !prevState)}
														/>
													) : (
														<BsEyeSlash
															className="view-pwd"
															size={20}
															onClick={() => setVisible1((prevState) => !prevState)}
														/>
													)}
												</div>
												<ErrorMessage name="password1" component="div" className="text-red-500" />
											</div>
											<button
												type="submit"
												disabled={isLoading}
												className="btn w-full bg-[#0372CE] text-white p-[15px] text-[1.4rem] mt-[40px]"
											>
												Sign Up
											</button>
										</form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-6 all-i-ask">
					<div className="">
						<h1 className="courses-you-need">All the Courses You Need in One Place</h1>
						<p className="courses-you-need-sub">
							This should include a clear description of those learners that will find your course valuable.
						</p>
						<img className="all-i-ask2" src={register} alt="back" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrganizationSignUp;

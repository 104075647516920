// import { useState, useEffect } from 'react';
// import NavLinks from './NavLinks';

import './Home.css'
// import logo from '../../assets/classCubeLogo.png';
import dash from '../../assets/Admin view dashboard.png';
// import heroBg from '../../assets/image.png';

import { Link } from 'react-router-dom';
import TopNav from './TopNav';

const Header = () => {
    // const [isOpen, setIsOpen] = useState(false);

    // const openMobileMenu = () => {
    //     setIsOpen((menu) => !menu)
    // }

    // const [scrolling, setScrolling] = useState(false);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.scrollY > 0) {
    //             setScrolling(true);
    //         } else {
    //             setScrolling(false);
    //         }
    //     };

    //     window.addEventListener("scroll", handleScroll);

    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);


    return (
        <header className='header min-h-screen'>
            <TopNav />
            {/* <nav className={`web-nav fixed w-full top-0 left-0 right-0  transition-colors light-blue ${scrolling ? "" : "bg-transparent"}`}>
                <div className="container mx-auto flex items-center justify-between py-4">
                    <div className="logo">
                        <img src={logo} alt="Logo" />
                    </div>

                    <div className="cta flex items-center">
                        <NavLinks />
                        <button className='bg-blue px-8 py-4 text-white rounded-md ml-4 shadow-blue'>
                            <Link to="/register">Get started</Link>
                        </button>
                    </div>
                </div>
            </nav>

            <nav className={`mobile-nav fixed w-full top-0 left-0 right-0 transition light-blue ${scrolling ? "" : "bg-transparent"}`}>
                <div className="container mx-auto flex items-center justify-between py-4">
                    <div className="logo">
                        <img src={logo} alt="Logo" />
                    </div>

                    <button className="icon menu-btn" onClick={openMobileMenu}>
                        <i className="ri-menu-5-line text-5xl">w</i>
                    </button>
                </div>
                <div className={`mobile-content ${isOpen ? 'open' : ''}`}>
                    <div className="cta flex items-center">
                        <NavLinks />
                        <button className='bg-blue px-8 py-3 text-white rounded-md w-[50%] shadow-blue'>
                            <Link to="/register">Get started</Link>
                        </button>
                    </div>
                </div>

            </nav> */}

            <div className="hero container pt-44">
                <div className="content text-center mb-36">
                    <h1 className='text-7xl lg:text-8xl font-semibold color-primary mx-auto mb-5 leading-normal'>
                        <h2 className='mb-2' >Own your own online <br /></h2>
                        <h2> Education platform</h2>
                    </h1>

                    <p className='color-secondary text-main mx-auto mb-14 leading-relaxed'>ClassCube Enterprise offers corporate organizations the power of owning their personalized space within our learning management system. Customize, brand, and transform the platform into a reflection of your unique identity, just like owning a franchise, but for professional development.</p>
                    <div className="flex items-center justify-center">
                        <Link to="/register">
                            <button className='bg-[#0C78F1] px-8 py-3 text-white rounded-md shadow-blue hover:bg-[#0062cc]'>
                                Get started
                            </button>
                        </Link>
                        <button className='px-8 py-3 rounded-md ml-4 btn'>
                            <a href="#Benefits" >Learn More</a>
                        </button>
                    </div>
                </div>
                <div className="hero-img relative">
                    <div className="gradient"></div>
                    <img className='mx-auto' src={dash} alt="dashboard" />
                </div>
            </div>
        </header>
    )
}

export default Header